var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',{staticClass:"pa-3",attrs:{"value":"tab-nft"}},[_c('paged-data-table',{attrs:{"namespace":_vm.getNamespace,"itemEndpoint":_vm.getTableEndpoint,"itemsHeaders":_vm.getHeaders,"itemKey":"id","query":_vm.getSearchQuery,"showExpand":true,"sortBy":"value","sortDesc":true,"isCard":false},scopedSlots:_vm._u([{key:"item.totalBalance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("HideValueFormatter")(item.totalBalance))+" ")]}},{key:"item.collection",fn:function(ref){
var item = ref.item;
return [_c('nft-component',{attrs:{"image":item.collection.collectionProfileImage,"name":_vm.getCollectionName(item),"symbol":_vm.getCollectionName(item)}})]}},{key:"item.floor",fn:function(ref){
var item = ref.item;
return [(_vm.getSortedItemCollectionStatsByMarketplace(item).length > 0)?_vm._l((_vm.getSortedItemCollectionStatsByMarketplace(item)),function(stat,i){return _c('v-row',{key:i,attrs:{"align":'center'}},[(i == 0)?[_c('coin-value-component',{attrs:{"image":_vm.getCollectionBlockchainCoinImage(item),"quantity":stat.floorPrice,"symbol":item.collection.blockchainCoin.symbol,"value":stat.floorPrice * item.collection.blockchainCoin.lastPrice.prices[_vm.loggedUser.settings.currency]}})]:_vm._e()],2)}):[_vm._v(" - ")]]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("CurrencyFormatter")(item.totalValue,_vm.loggedUser.settings.currency))+" ")]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
var item = ref.item;
return [_c('td',{staticClass:"text-start"},[_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{ 'v-data-table__expand-icon--active': isExpanded },attrs:{"icon":""},on:{"click":function($event){expand(!isExpanded); _vm.expandItem(item.collection)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1)]}},{key:"expanded-item",fn:function(ref){
var balanceItem = ref.item;
return [_c('td',{attrs:{"colspan":_vm.getHeaders.length}},[_c('v-data-table',{staticClass:"expanded-datatable",attrs:{"disable-pagination":true,"headers":_vm.getExpandedHeaders,"items":_vm.sortedBalances(balanceItem, balanceItem.collection),"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.blockchainType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-2"},[_c('v-avatar',{attrs:{"size":"25"}},[_c('v-img',{attrs:{"src":require(("../../assets/blockchain/" + (item.blockchainType) + ".svg")),"alt":"","width":"25","aspect-ratio":"1","contain":""}})],1)],1),_c('span',[_vm._v(_vm._s(_vm._f("BlockchainTypeFormatter")(item.blockchainType)))])]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("PersonalAddressFormatter")(item.address))+" ")]}},{key:"item.asset",fn:function(ref){
var item = ref.item;
return [_c('nft-component',{attrs:{"image":item.nft.tokenImage,"name":item.nft.tokenName ? item.nft.tokenName : item.nft.tokenId,"symbol":item.nft.tokenName ? item.nft.tokenName : item.nft.tokenId,"quantity":item.balance}})]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("CurrencyFormatter")(_vm.getAssetEstimatedValue(item, balanceItem.collection),_vm.loggedUser.settings.currency))+" ")]}}],null,true)})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }