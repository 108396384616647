<template>
  <div>
    <v-btn @click="deleteAccountDialog = true" tile color="error">
      {{ $t('label.deleteAccount') }}
    </v-btn>
    <confirm-dialog :title="$t('account.deleteAccountTitle')" :message="$t('address.deleteAccountMessage')"
      :showDialog="deleteAccountDialog" :loading="loading" @confirmed="onConfirmed" :isError="true"
      :confirmButtonText="$t('account.deleteAccountConfirmButton')">
      <template v-slot:body>
          <span >{{ $t('account.deleteAccountMessage') }}</span>
          <v-alert class="mt-3" dense icon="mdi-alert" outlined type="error">
              {{ $t('account.accountDeleteDialogInfo') }}
          </v-alert>
      </template>
    </confirm-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmDialog from '../dialog/ConfirmDialog.vue';
export default {
  name: 'delete-account-button',
  props: {
    showAlert: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    ConfirmDialog
  },
  data() {
    return {
      loading: false,
      deleteAccountDialog: false,
    }
  },
  computed: {
  },
  methods: {
    ...mapActions("loggedUser", ["deleteUserAccount"]),
    ...mapActions("alert", ["createAlert"]),
    onConfirmed(confirmed) {
      if (confirmed) {
        this.deleteAccount()
      } else {
        this.deleteAccountDialog = false;
      }
    },
    deleteAccount() {
      this.loading = true;
      this.deleteUserAccount().then(() => {
        this.createAlert({ message: this.$t('message.deleteAccountSuccess'), type: "success" })
        this.$emit("accountDeleted", true);
      }).catch(() => {
        this.createAlert({ message: this.$t('message.genericError'), type: "error" })
      }).finally(() => {
        this.deleteAccountDialog = false;
        this.loading = false
      });
    },
  
  }

}
</script>

<style>
</style>