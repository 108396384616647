<template>
    <div>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <div justify="center">
                    <v-icon @click="selectDocument" v-on="on" size="25">
                        mdi-pencil-circle-outline
                    </v-icon>
                </div>
            </template>
            <span>{{ $t('message.updateFile') }}</span>
        </v-tooltip>
        <update-external-data-dialog :dialog.sync="updateDataDialog" @sourceUpdated="onUpdated"></update-external-data-dialog>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import UpdateExternalDataDialog from '../dialog/UpdateExternalDataDialog.vue';
export default {
    name: 'update-external-data-button',
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    components: {
        UpdateExternalDataDialog
    },
    data() {
        return {
            loading: false,
            updateDataDialog: false,
        }
    },
    computed: {
        ...mapFields("externalSource", ["selectedDocument"])
    },
    methods: {
        ...mapActions("externalSource", ["updateExternalData"]),
        ...mapActions("alert", ["createAlert"]),
        selectDocument(){
            this.updateDataDialog = true;
            this.selectedDocument.name = this.document.name;
            this.selectedDocument.exchange = this.document.exchangeType;
            this.selectedDocument.id = this.document.id;
            this.selectedDocument.fileName = this.document.fileName;
            this.selectedDocument.file = null;
            this.selectedDocument.errorType = this.document.errorType;
            this.selectedDocument.errorHint = this.document.errorHint;
            this.selectedDocument.documentStatusType = this.document.documentStatusType;
        },
        onUpdated(sourceUpdated) {
            if (sourceUpdated) {
                this.$emit("dataUpdated", true);
            } else {
                this.updateDataDialog = false;
            }
        },
    }

}
</script>

<style>

</style>