<template>
  <v-container fluid>
    <v-row
      no-gutters
      :align="'center'"
      :justify="'center'"
      v-if="$vuetify.breakpoint.smAndUp"
    >
      <v-spacer />
      <v-col cols="auto">
        <h1 class="mr-3 pb-8">{{ $t("plan.pricingPlansTitle") }}</h1>
      </v-col>
      <v-col cols="auto">
        <v-select
          :items="availableTaxYears"
          outlined
          item-color="accent"
          v-model="taxYear"
          :style="{ width: '140px' }"
        >
          <template v-slot:selection="{ item }">
            <h1 class="accent--text">{{ item }}</h1>
          </template>
        </v-select>
      </v-col>
      <v-spacer />
    </v-row>
    <template v-if="$vuetify.breakpoint.xs">
      <v-row no-gutters :align="'center'" :justify="'center'">
        <v-col :align="'center'" :justify="'center'">
          <h1>{{ $t("plan.pricingPlansTitle") }}</h1>
        </v-col>
      </v-row>
      <v-row no-gutters :align="'center'" :justify="'center'">
        <v-select
          :items="getAvailableTaxYears"
          outlined
          item-color="accent"
          v-model="taxYear"
          class="pt-8"
          :style="{ width: '140px' }"
        >
          <template v-slot:selection="{ item }">
            <h1 class="accent--text">{{ item }}</h1>
          </template>
        </v-select>
      </v-row>
    </template>
    <v-row :align="'end'" no-gutters v-if="!$vuetify.breakpoint.xs">
      <v-col cols="auto">
        <v-alert type="info" outlined dense>
          <template v-slot:prepend>
            <v-icon color="accent" class="mr-3">mdi-information-outline</v-icon>
          </template>
          <v-row :align="'center'">
            <v-col>
              {{ $t("tax.requestAccountantAlert") }}
            </v-col>
            <v-col cols="auto">
              <v-btn @click="contactUs" icon>
                <v-icon color="accent">mdi-email-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-spacer />
      <v-col :align="'center'" :justify="'center'" cols="auto" class="pb-5">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                @click="$router.push('/create-tax-report')"
                color="accent"
                class="white--text pa-0"
                elevation="0"
                tile
                :disabled="
                  anyPersonalAddressUpdating ||
                  !anyTransactionPreviousYears ||
                  !anyPersonalAddress || (availableTaxYears.length == 1 && availableTaxYears[0] == 2024)
                "
              >
                <div class="button-corner">
                  <div class="button-transparent-triangle"></div>
                  <div class="button-colored-triangle"></div>
                </div>
                <span class="px-3">{{
                  $t("tax.downloadTaxReportButton")
                }}</span>
              </v-btn>
            </span>
          </template>
          <span v-if="anyPersonalAddressUpdating">{{
            $t("message.taxReportAnyPersonalAddressUpdating")
          }}</span>
          <span v-else-if="!anyPersonalAddress">{{
            $t("message.taxReportNoPersonalAddress")
          }}</span>
          <span v-else-if="!anyTransactionPreviousYears">{{
            $t("message.taxReportNoTransactionPreviousYears")
          }}</span>
          <!-- Remove -->
          <span v-else-if="availableTaxYears.length == 1 && availableTaxYears[0] == 2024">{{
            $t("message.taxReportNoCurrentYear")
          }}</span>
          <span v-else>{{ $t("message.createTaxReportTooltip") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row :align="'end'" no-gutters v-if="$vuetify.breakpoint.xs">
      <v-col cols="auto">
        <v-alert type="info" outlined dense>
          <template v-slot:prepend>
            <v-icon color="accent" class="mr-3">mdi-information-outline</v-icon>
          </template>
          <v-row :align="'center'">
            <v-col>
              {{ $t("tax.requestAccountantAlert") }}
            </v-col>
            <v-col cols="auto">
              <v-btn @click="contactUs" icon>
                <v-icon color="accent">mdi-email-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.xs">
      <v-spacer></v-spacer>
      <v-col :align="'center'" :justify="'center'" cols="auto" class="pb-5">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              <v-btn
                @click="$router.push('/create-tax-report')"
                color="accent"
                class="white--text pa-0"
                elevation="0"
                tile
                :disabled="
                  anyPersonalAddressUpdating ||
                  !anyTransactionPreviousYears ||
                  !anyPersonalAddress || (availableTaxYears.length == 1 && availableTaxYears[0] == 2024)
                "
              >
                <div class="button-corner">
                  <div class="button-transparent-triangle"></div>
                  <div class="button-colored-triangle"></div>
                </div>
                <span class="px-3">{{
                  $t("tax.downloadTaxReportButton")
                }}</span>
              </v-btn>
            </span>
          </template>
          <span v-if="anyPersonalAddressUpdating">{{
            $t("message.taxReportAnyPersonalAddressUpdating")
          }}</span>
          <span v-else-if="!anyPersonalAddress">{{
            $t("message.taxReportNoPersonalAddress")
          }}</span>
          <span v-else-if="!anyTransactionPreviousYears">{{
            $t("message.taxReportNoTransactionPreviousYears")
          }}</span>
          <!-- Remove -->
          <span v-else-if="(availableTaxYears.length == 1 && availableTaxYears[0] == 2024)">{{
            $t("message.taxReportNoCurrentYear")
          }}</span>
          <span v-else>{{ $t("message.createTaxReportTooltip") }}</span>
        </v-tooltip>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row class="pa-0">
      <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
        <v-text-field
          outlined
          readonly
          :value="
            hasCreatedTaxDocuments
              ? $options.filters.DateFormatter(getFirstDocument.created)
              : '-'
          "
          :label="$t('label.createdAt')"
        >
        </v-text-field>
      </v-col>
      <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
        <v-text-field
          outlined
          readonly
          :value="
            hasCreatedTaxDocuments ? getFirstDocument.addresses.length : '-'
          "
          :label="$t('label.totalConnections')"
        >
        </v-text-field>
      </v-col>
      <!--
      <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
        <v-text-field
          outlined
          readonly
          :value="hasCreatedTaxDocuments ? taxYearTransactions : '-'"
          :label="$t('label.totalTransactions')"
        >
        </v-text-field>
      </v-col>
      <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
        <v-text-field
          outlined
          readonly
          :value="
            hasCreatedTaxDocuments
              ? $options.filters.CostBasisMethodFormatter(
                  getFirstDocument.costBasisMethodType
                )
              : '-'
          "
          :label="$t('label.costBasisMethod')"
        >
        </v-text-field>
      </v-col>
      <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
        <v-text-field
          outlined
          readonly
          :value="hasCreatedTaxDocuments ? $options.filters.CountryTypeFormatter(getFirstDocument.countryType) : '-'"
          :label="$t('label.country')"
        >
          <template v-slot:prepend-inner>
            <template v-if="hasCreatedTaxDocuments">
              <v-avatar size="20">
                <v-img
                  :src="
                    require(`../../assets/country/${getFirstDocument.countryType}.svg`)
                  "
                ></v-img>
              </v-avatar>
            </template>
          </template>
        </v-text-field>
      </v-col>
      <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
        <v-text-field
          outlined
          readonly
          :value="hasCreatedTaxDocuments ? getFirstDocument.currencyType : '-'"
          :label="$t('label.currency')"
        >
          <template v-slot:prepend-inner>
            <template v-if="hasCreatedTaxDocuments">
              <v-avatar size="20">
                <v-img
                  :src="
                    require(`../../assets/currency/${getFirstDocument.currencyType}.svg`)
                  "
                ></v-img>
              </v-avatar>
            </template>
          </template>
        </v-text-field>
      </v-col>
      -->
      <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
        <v-text-field
          outlined
          readonly
          :value="
            hasCreatedTaxDocuments
              ? $options.filters.YesNoFormatter(getFirstDocument.emoney)
              : '-'
          "
          :label="$t('tax.emoney')"
        >
          <template v-slot:prepend-inner>
            <template v-if="hasCreatedTaxDocuments">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                <span>
                  {{ $t("tax.emoneyTooltip") }}
                </span>
              </v-tooltip>
            </template>
          </template>
        </v-text-field>
      </v-col>
      <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
        <v-text-field
          outlined
          readonly
          :value="
            hasCreatedTaxDocuments
              ? $options.filters.YesNoFormatter(getFirstDocument.threshold)
              : '-'
          "
          :label="$t('tax.threshold')"
        >
          <template v-slot:prepend-inner>
            <template v-if="hasCreatedTaxDocuments">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                <span>
                  {{ $t("tax.thresholdTooltip") }}
                </span>
              </v-tooltip>
            </template>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="!$vuetify.breakpoint.xs" :align="'start'" :justify="'start'">
      <v-col cols="auto">
        <v-alert
          type="error"
          v-if="
            hasCreatedTaxDocuments &&
            taxDocuments.length != 1 &&
            !areDocumentsUpdated
          "
          outlined
        >
          {{ $t("tax.taxReportNotUpdated") }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="hasCreatedTaxDocuments && taxDocuments.length != 1" no-gutters>
      <v-col
        v-for="(taxDocument, i) in taxDocuments"
        :key="i"
        xl="2"
        lg="3"
        md="4"
        sm="6"
        xs="12"
        cols="12"
      >
        <report-card :taxDocument="taxDocument" :taxYear="taxYear"></report-card>
      </v-col>
    </v-row>
    <v-row v-else-if="hasCreatedTaxDocuments && taxDocuments.length == 1">
      <v-col class="text-center align-center justify-center mt-15">
        <v-progress-circular
          indeterminate
          size="100"
          :width="10"
          color="accent"
        ></v-progress-circular>
        <p class="mt-5">{{ $t("tax.circularProgressSentence") }}</p>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-alert type="info" outlined dense>
          <template v-slot:prepend>
            <v-icon color="accent" class="mr-3">mdi-information-outline</v-icon>
          </template>
          <template v-if="taxYear == 2024">
            {{ $t("tax.taxReportWarning") }}
          </template>
          <template v-else>
            {{ $t("tax.noComputedReports") }}
          </template>

        </v-alert>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
//import ProFeatureDialog from "../../components/dialog/ProFeatureDialog.vue";
//import TaxReportDownloadButton from "../../components/button/TaxReportDownloadButton.vue";
import ReportCard from "../../components/card/ReportCard.vue";
export default {
  name: "user-tax-center-page",
  components: {
    //ProFeatureDialog,
    //TaxReportDownloadButton,
    ReportCard,
  },
  data() {
    return {
      dialog: false,
      refresh: false,
      showProDialog: false,
      menu: false,
      picker: null,
      currentYear: new Date().getFullYear(),
      taxYear: new Date().getFullYear() - 1,
    };
  },
  computed: {
    ...mapFields("tax", [
      "availableTaxYears",
      "totalTransactions",
      "taxDocuments",
      "taxYearTransactions",
    ]),
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapFields("userAddresses", ["addresses"]),
    getAvailableTaxYears() {
      if (this.availableTaxYears.length == 0) {
        return [];
      }
      // Get oldest year available and create a list of years until current year - 1
      let oldestYear = this.availableTaxYears.slice(-1)[0];
      let years = [];
      for (let i = oldestYear; i < this.currentYear; i++) {
        years.push(i);
      }
      // Reverse sort
      years.sort((a, b) => b - a);
      return years;
    },
    anyPersonalAddressUpdating() {
      return this.addresses
        .filter((x) => x.userAddressType == "PERSONAL")
        .some((x) => x.address.updating);
    },
    anyPersonalAddress() {
      return (
        this.addresses.filter((x) => x.userAddressType == "PERSONAL").length > 0
      );
    },
    anyTransactionPreviousYears() {
      return this.getAvailableTaxYears.length > 0;
    },
    getFirstDocument() {
      return this.taxDocuments[0];
    },
    hasCreatedTaxDocuments() {
      return this.taxDocuments ? this.taxDocuments.length > 0 : false;
    },
    areDocumentsUpdated() {
      for (let i = 0; i < this.addresses.length; i++) {
        let address = this.addresses[i].address;
        if (!this.getFirstDocument.addresses.includes(address.address)) {
          return false;
        } else {
          // Check if address update date is after the tax document creation date
          let addressUpdateDate = new Date(address.updated);
          let taxDocumentCreationDate = new Date(this.getFirstDocument.created);
          if (addressUpdateDate > taxDocumentCreationDate) {
            return false;
          } else {
            continue;
          }
        }
      }
      return true;
    },
    isCurrentTaxYear() {
      return this.taxYear == new Date().getFullYear() - 1;
    },
    isDownloadAvailable() {
      if (this.loggedUser.userRole != "USER") {
        return true;
      }
      if (this.taxYearTransactions <= 50) {
        return true;
      } else {
        return this.loggedUser.pricingPlan.taxYearsAvailable.some(
          (x) =>
            x.taxYear == this.taxYear &&
            x.transactionLimit >= this.taxYearTransactions
        );
      }
    },
    getDownloadNotAvailableTooltip() {
      let transactions = this.taxYearTransactions;
      let neededPlan = this.getNeededPlan(transactions);
      if (neededPlan == "ENTERPRISE") {
        return this.$t("message.contactUs");
      } else {
        if (this.loggedUser.externalTier != null) {
          return this.$t("message.buyPlanYoungPlatform");
        }
        return this.$t("message.buyPlan", {
          plan: neededPlan,
          taxYear: this.taxYear,
        });
      }
    },
    getSubject() {
      return this.$t("message.contactUsSubject");
    },
    getAccountantSubject() {
      return this.$t("message.contactUsAccountantSubject");
    },
  },
  methods: {
    ...mapActions("tax", [
      "fetchAvailableTaxYears",
      "fetchCurrentTaxYearTransactions",
      "fetchTaxDocuments",
      "askForAccountant",
      "fetchTaxYearTransactions",
    ]),
    fetchData(success) {
      if (success) {
        this.refresh = true;
      }
    },
    contactUs() {
      this.askForAccountant();
      var mail = `mailto:support@okipo.io?subject=${this.getAccountantSubject}`;
      var a = document.createElement("a");
      a.href = mail;
      a.click();
    },
    saveYear(year) {
      this.taxYear = year;
      this.menu = false;
    },
    clickDownloadReport() {
      let transactions = this.taxYearTransactions;
      let neededPlan = this.getNeededPlan(transactions);
      if (neededPlan == "ENTERPRISE") {
        this.sendEmail();
      } else {
        this.$router.push({
          name: "Pricing Plans Page",
          params: { taxYear: this.taxYear },
        });
      }
    },
    getIcon(item) {
      switch (item.fileName.split(".")[1]) {
        case "pdf":
          return "mdi-file-pdf-box";
        case "xlsx":
          return "mdi-file-excel-box";
        default:
          return "mdi-file";
      }
    },
    getColor(item) {
      switch (item.fileName.split(".")[1]) {
        case "pdf":
          return "#F40F02";
        case "xlsx":
          return "#10793F";
        default:
          return "info";
      }
    },
    getNeededPlan(transactions) {
      if (transactions <= 1000) {
        return this.$options.filters.PricingPlanFormatter("BASE");
      } else if (transactions <= 3000) {
        return this.$options.filters.PricingPlanFormatter("STANDARD");
      } else if (transactions <= 10000) {
        return this.$options.filters.PricingPlanFormatter("PREMIUM");
      } else {
        return "ENTERPRISE";
      }
    },
    sendEmail() {
      var mail = `mailto:support@okipo.io?subject=${this.getSubject}`;
      var a = document.createElement("a");
      a.href = mail;
      a.click();
    },
  },
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    taxYear(val, oldVal) {
      if (val !== oldVal) {
        this.fetchTaxDocuments(this.taxYear);
        this.fetchTaxYearTransactions(this.taxYear);
      }
    },
  },
  created() {
    this.fetchAvailableTaxYears();
    this.fetchCurrentTaxYearTransactions();
    this.fetchTaxDocuments(this.taxYear);
    this.fetchTaxYearTransactions(this.taxYear);
  },
};
</script>

<style scoped>
.button-transparent-triangle {
  border-left: 10px solid transparent;
  border-right: 10px solid var(--v-background-base);
  border-bottom: 10px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 0px;
  z-index: 2;
}

.button-colored-triangle {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: rgba(255, 255, 255, 0.5);
}

.button-corner {
  position: absolute;
  top: -10px;
  right: 0;
  width: 10px;
  height: 10px;
}
</style>