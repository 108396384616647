<template>
  <v-container fluid>
    <v-row class="my-10">
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
      <v-col>
        <payment-cancelled-card></payment-cancelled-card>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>
    </v-row>
    <v-row :align="'center'" :justify="'center'" no-gutters>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <router-button :text="$t('plan.paymentCancelButton')" redirect="/pricing-plans"></router-button>
      </v-col>
      <v-spacer></v-spacer>      
          </v-row>
  </v-container>
</template>

<script>
import PaymentCancelledCard from '../../components/card/PaymentCancelledCard.vue'
import RouterButton from '../../components/button/RouterButton.vue';
export default {
    name: "payment-cancel-page",
    components: {
        PaymentCancelledCard,
        RouterButton
    },
}
</script>

<style>

</style>