<template>
  <v-container fluid :style="cssProps">
    <v-card
      class="custom-card pa-2"
      elevation="0"
      :class="{ pdfCard: isPDFDocument, excelCard: isExcelDocument }"
    >
      <div class="card-corner">
        <div class="transparent-triangle"></div>
        <div class="colored-triangle"></div>
      </div>
      <v-card-title class="card-title">
        {{ taxDocument.taxDocumentType | TaxDocumentTypeFormatter }}
      </v-card-title>
      <v-card-subtitle >
        <span class="card-subtitle">{{ taxDocument.taxYear }}</span>
      </v-card-subtitle>
      <v-card-text>
        <!-- Dynamic Description -->
        <p class="description">
          {{ taxDocument.taxDocumentType | TaxDocumentTypeDescriptionFormatter }}
        </p>

        <!-- PDF Mock Document -->
        <div v-if="isPDFDocument" class="pdf-mock">
          <div v-for="row in 6" :key="'pdf-' + row" class="pdf-line"></div>
        </div>

        <!-- Excel Mock Document -->
        <div v-if="isExcelDocument" class="excel-background">
          <div v-for="row in 6" :key="'excel-' + row" class="excel-row">
            <span v-for="col in 5" :key="'excel-' + row + '-' + col" class="excel-cell"></span>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <!-- Icon for PDF/Excel -->
        <v-icon x-large class="action-icon">
          {{ getIcon(taxDocument) }}
        </v-icon>
        <!-- Spacer for alignment -->
        <v-spacer />

        <div v-if="isDownloadAvailable">
          <template v-if="taxDocument.fileId != null">
                    <tax-report-download-button v-if="taxDocument.fileId != null"
                      :taxDocument="taxDocument"></tax-report-download-button>
                  </template>
        </div>
        <div v-else :align="'start'" :justify="'center'">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon @click="clickDownloadReport" v-on="on" color="accent">
                        mdi-share
                      </v-icon>
                    </template>
                    <span>{{ getDownloadNotAvailableTooltip }}</span>
                  </v-tooltip>
                </div>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import TaxReportDownloadButton from "../button/TaxReportDownloadButton.vue";
export default {
  name: "report-card",
  components: {
    TaxReportDownloadButton,
  },
  props: {
    taxDocument: {
      type: Object,
      required: true,
    },
    taxYear: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapFields("tax", [
      "taxYearTransactions",
    ]),
    isPDFDocument() {
      return this.taxDocument.fileName.split(".")[1] === "pdf";
    },
    isExcelDocument() {
      return this.taxDocument.fileName.split(".")[1] === "xlsx";
    },
    cssProps() {
      return {
        "--report-background-color": this.getColor(this.taxDocument),
      };
    },
    isDownloadAvailable() {
      if (this.loggedUser.userRole != 'USER') {
        return true;
      }
      return this.loggedUser.pricingPlan.taxYearsAvailable.some(x => x.taxYear == this.taxYear && x.transactionLimit >= this.taxYearTransactions)
    },
    getDownloadNotAvailableTooltip() {
      let transactions = this.taxYearTransactions;
      let neededPlan = this.getNeededPlan(transactions);
      if (neededPlan == "ENTERPRISE") {
        return this.$t('message.contactUs')
      } else {
        if (this.loggedUser.externalTier != null){
          return this.$t('message.buyPlanYoungPlatform')
        }
        return this.$t('message.buyPlan', { plan: neededPlan, taxYear: this.taxYear })
      }
    },
  },
  methods: {
    getNeededPlan(transactions) {
      if (transactions <= 50) {
        return this.$options.filters.PricingPlanFormatter('LITE')
      } else if (transactions <= 1000) {
        return this.$options.filters.PricingPlanFormatter('BASE')
      } else if (transactions <= 3000) {
        return this.$options.filters.PricingPlanFormatter('STANDARD')
      } else if (transactions <= 10000) {
        return this.$options.filters.PricingPlanFormatter('PREMIUM')
      } else {
        return "ENTERPRISE"
      }
    },
    clickDownloadReport() {
      let transactions = this.taxYearTransactions;
      let neededPlan = this.getNeededPlan(transactions);
      if (neededPlan == "ENTERPRISE") {
        this.sendEmail()
      } else {
        this.$router.push({ name: 'Pricing Plans Page', params: { taxYear: this.taxYear } });
      }
    },
    getColor(item) {
      switch (item.fileName.split(".")[1]) {
        case "pdf":
          return "#F40F02"; // PDF red
        case "xlsx":
          return "#10793F"; // Excel green
        default:
          return "info";
      }
    },
    getIcon(item) {
      switch (item.fileName.split(".")[1]) {
        case "pdf":
          return "mdi-file-pdf-box";
        case "xlsx":
          return "mdi-file-excel-box";
        default:
          return "mdi-file";
      }
    },
    downloadFile() {
      const fileUrl = `/path-to-download/${this.taxDocument.fileId}`;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = this.taxDocument.fileName;
      link.click();
    },
  },
};
</script>

<style scoped>
/* General Card Styling */
.custom-card {
  position: relative;
  border-bottom: solid 5px var(--report-background-color);
}
.pdfCard {
  background-color: rgba(255, 255, 255, 0.05); /* PDF Red */
}
.excelCard {
  background-color: rgba(255, 255, 255, 0.05); /* Excel Green */
}
/* Light Mode Styling */
.theme--light .pdfCard {
  background-color: rgba(0, 0, 0, 0.05); /* Light Mode */
}
.theme--light .excelCard {
  background-color: rgba(0, 0, 0, 0.05); /* Light Mode */
}
/* Titles and Descriptions */
.card-title {
  font-size: 1.0rem;
  font-weight: bold;
  text-transform: uppercase;

}
.card-subtitle {
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;

}
.description {
  font-size: 0.8rem;
  margin-bottom: 12px;
  font-style: italic;
}

/* PDF Mock Document */
.pdf-mock {
  background: white;
  border-radius: 4px;
  padding: 8px;
  margin-top: 10px;
  overflow: hidden;
  height: 120px; /* Adjust height for document area */
}
.pdf-line {
  width: 100%;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.1); /* Light gray for text lines */
  border-radius: 2px;
  margin-bottom: 5px;
}

/* Excel Mock Document */
.excel-background {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0;
}
.excel-row {
  display: flex;
  gap: 4px;
}
.excel-cell {
  width: 20%;
  height: 15px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 2px;
}

/* Card Actions */
.action-icon {
  color: var(--report-background-color);
}

/* Button Styling */
.custom-button {
  background-color: var(--report-background-color);
  color: var(--report-background-color);

}
.custom-button:hover {
  background-color: var(--report-background-color);
  color: white;
}

/* Decorative Corner Elements */
.card-corner {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
}
.transparent-triangle {
  border-left: 50px solid transparent;
  border-right: 50px solid var(--v-background-base);
  border-bottom: 50px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 0px;
  z-index: 2;
}
.colored-triangle {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 0 50px;
  border-color: var(--report-background-color);
}
</style>