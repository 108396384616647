var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":"","align":'center',"justify":'center'}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('h1',{staticClass:"mr-3 pb-8"},[_vm._v(_vm._s(_vm.$t("plan.pricingPlansTitle")))])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{style:({ width: '140px' }),attrs:{"items":_vm.availableTaxYears,"outlined":"","item-color":"accent"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('h1',{staticClass:"accent--text"},[_vm._v(_vm._s(item))])]}}],null,false,3484709841),model:{value:(_vm.taxYear),callback:function ($$v) {_vm.taxYear=$$v},expression:"taxYear"}})],1),_c('v-spacer')],1):_vm._e(),(_vm.$vuetify.breakpoint.xs)?[_c('v-row',{attrs:{"no-gutters":"","align":'center',"justify":'center'}},[_c('v-col',{attrs:{"align":'center',"justify":'center'}},[_c('h1',[_vm._v(_vm._s(_vm.$t("plan.pricingPlansTitle")))])])],1),_c('v-row',{attrs:{"no-gutters":"","align":'center',"justify":'center'}},[_c('v-select',{staticClass:"pt-8",style:({ width: '140px' }),attrs:{"items":_vm.getAvailableTaxYears,"outlined":"","item-color":"accent"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('h1',{staticClass:"accent--text"},[_vm._v(_vm._s(item))])]}}],null,false,3484709841),model:{value:(_vm.taxYear),callback:function ($$v) {_vm.taxYear=$$v},expression:"taxYear"}})],1)]:_vm._e(),(!_vm.$vuetify.breakpoint.xs)?_c('v-row',{attrs:{"align":'end',"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-alert',{attrs:{"type":"info","outlined":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":"accent"}},[_vm._v("mdi-information-outline")])]},proxy:true}],null,false,2067686496)},[_c('v-row',{attrs:{"align":'center'}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("tax.requestAccountantAlert"))+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.contactUs}},[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-email-outline")])],1)],1)],1)],1)],1),_c('v-spacer'),_c('v-col',{staticClass:"pb-5",attrs:{"align":'center',"justify":'center',"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"white--text pa-0",attrs:{"color":"accent","elevation":"0","tile":"","disabled":_vm.anyPersonalAddressUpdating ||
                !_vm.anyTransactionPreviousYears ||
                !_vm.anyPersonalAddress || (_vm.availableTaxYears.length == 1 && _vm.availableTaxYears[0] == 2024)},on:{"click":function($event){return _vm.$router.push('/create-tax-report')}}},[_c('div',{staticClass:"button-corner"},[_c('div',{staticClass:"button-transparent-triangle"}),_c('div',{staticClass:"button-colored-triangle"})]),_c('span',{staticClass:"px-3"},[_vm._v(_vm._s(_vm.$t("tax.downloadTaxReportButton")))])])],1)]}}],null,false,4106150993)},[(_vm.anyPersonalAddressUpdating)?_c('span',[_vm._v(_vm._s(_vm.$t("message.taxReportAnyPersonalAddressUpdating")))]):(!_vm.anyPersonalAddress)?_c('span',[_vm._v(_vm._s(_vm.$t("message.taxReportNoPersonalAddress")))]):(!_vm.anyTransactionPreviousYears)?_c('span',[_vm._v(_vm._s(_vm.$t("message.taxReportNoTransactionPreviousYears")))]):(_vm.availableTaxYears.length == 1 && _vm.availableTaxYears[0] == 2024)?_c('span',[_vm._v(_vm._s(_vm.$t("message.taxReportNoCurrentYear")))]):_c('span',[_vm._v(_vm._s(_vm.$t("message.createTaxReportTooltip")))])])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.xs)?_c('v-row',{attrs:{"align":'end',"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-alert',{attrs:{"type":"info","outlined":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":"accent"}},[_vm._v("mdi-information-outline")])]},proxy:true}],null,false,2067686496)},[_c('v-row',{attrs:{"align":'center'}},[_c('v-col',[_vm._v(" "+_vm._s(_vm.$t("tax.requestAccountantAlert"))+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.contactUs}},[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-email-outline")])],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.xs)?_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"pb-5",attrs:{"align":'center',"justify":'center',"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{staticClass:"white--text pa-0",attrs:{"color":"accent","elevation":"0","tile":"","disabled":_vm.anyPersonalAddressUpdating ||
                !_vm.anyTransactionPreviousYears ||
                !_vm.anyPersonalAddress || (_vm.availableTaxYears.length == 1 && _vm.availableTaxYears[0] == 2024)},on:{"click":function($event){return _vm.$router.push('/create-tax-report')}}},[_c('div',{staticClass:"button-corner"},[_c('div',{staticClass:"button-transparent-triangle"}),_c('div',{staticClass:"button-colored-triangle"})]),_c('span',{staticClass:"px-3"},[_vm._v(_vm._s(_vm.$t("tax.downloadTaxReportButton")))])])],1)]}}],null,false,4106150993)},[(_vm.anyPersonalAddressUpdating)?_c('span',[_vm._v(_vm._s(_vm.$t("message.taxReportAnyPersonalAddressUpdating")))]):(!_vm.anyPersonalAddress)?_c('span',[_vm._v(_vm._s(_vm.$t("message.taxReportNoPersonalAddress")))]):(!_vm.anyTransactionPreviousYears)?_c('span',[_vm._v(_vm._s(_vm.$t("message.taxReportNoTransactionPreviousYears")))]):((_vm.availableTaxYears.length == 1 && _vm.availableTaxYears[0] == 2024))?_c('span',[_vm._v(_vm._s(_vm.$t("message.taxReportNoCurrentYear")))]):_c('span',[_vm._v(_vm._s(_vm.$t("message.createTaxReportTooltip")))])])],1),_c('v-spacer')],1):_vm._e(),_c('v-row',{staticClass:"pa-0"},[_c('v-col',{attrs:{"xl":"2","lg":"3","md":"4","sm":"6","xs":"12","cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","readonly":"","value":_vm.hasCreatedTaxDocuments
            ? _vm.$options.filters.DateFormatter(_vm.getFirstDocument.created)
            : '-',"label":_vm.$t('label.createdAt')}})],1),_c('v-col',{attrs:{"xl":"2","lg":"3","md":"4","sm":"6","xs":"12","cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","readonly":"","value":_vm.hasCreatedTaxDocuments ? _vm.getFirstDocument.addresses.length : '-',"label":_vm.$t('label.totalConnections')}})],1),_c('v-col',{attrs:{"xl":"2","lg":"3","md":"4","sm":"6","xs":"12","cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","readonly":"","value":_vm.hasCreatedTaxDocuments
            ? _vm.$options.filters.YesNoFormatter(_vm.getFirstDocument.emoney)
            : '-',"label":_vm.$t('tax.emoney')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.hasCreatedTaxDocuments)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tax.emoneyTooltip"))+" ")])])]:_vm._e()]},proxy:true}])})],1),_c('v-col',{attrs:{"xl":"2","lg":"3","md":"4","sm":"6","xs":"12","cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","readonly":"","value":_vm.hasCreatedTaxDocuments
            ? _vm.$options.filters.YesNoFormatter(_vm.getFirstDocument.threshold)
            : '-',"label":_vm.$t('tax.threshold')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.hasCreatedTaxDocuments)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,73716224)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("tax.thresholdTooltip"))+" ")])])]:_vm._e()]},proxy:true}])})],1)],1),(!_vm.$vuetify.breakpoint.xs)?_c('v-row',{attrs:{"align":'start',"justify":'start'}},[_c('v-col',{attrs:{"cols":"auto"}},[(
          _vm.hasCreatedTaxDocuments &&
          _vm.taxDocuments.length != 1 &&
          !_vm.areDocumentsUpdated
        )?_c('v-alert',{attrs:{"type":"error","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t("tax.taxReportNotUpdated"))+" ")]):_vm._e()],1)],1):_vm._e(),(_vm.hasCreatedTaxDocuments && _vm.taxDocuments.length != 1)?_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.taxDocuments),function(taxDocument,i){return _c('v-col',{key:i,attrs:{"xl":"2","lg":"3","md":"4","sm":"6","xs":"12","cols":"12"}},[_c('report-card',{attrs:{"taxDocument":taxDocument,"taxYear":_vm.taxYear}})],1)}),1):(_vm.hasCreatedTaxDocuments && _vm.taxDocuments.length == 1)?_c('v-row',[_c('v-col',{staticClass:"text-center align-center justify-center mt-15"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"100","width":10,"color":"accent"}}),_c('p',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.$t("tax.circularProgressSentence")))])],1)],1):_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-alert',{attrs:{"type":"info","outlined":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":"accent"}},[_vm._v("mdi-information-outline")])]},proxy:true}])},[(_vm.taxYear == 2024)?[_vm._v(" "+_vm._s(_vm.$t("tax.taxReportWarning"))+" ")]:[_vm._v(" "+_vm._s(_vm.$t("tax.noComputedReports"))+" ")]],2)],1),_c('v-spacer')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }