<template>
    <v-container fluid>
        <v-card class="pa-3" elevation="0">
            <v-list >
                <v-list-item-group v-model="tab">
                <v-list-item  @click="updateTab('account-tab')" >
                    <v-list-item-icon>
                        <v-icon>mdi-account-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $t('settings.accountTabTitle') }}
                    </v-list-item-title>
                    <v-list-item-action>
                        <v-icon>
                            mdi-chevron-right
                        </v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item @click="updateTab('subscription-tab')">
                    <v-list-item-icon>
                        <v-icon>mdi-credit-card-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $t('settings.subscriptionTabTitle') }}
                    </v-list-item-title>
                    <v-list-item-action>
                        <v-icon>
                            mdi-chevron-right
                        </v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item @click="updateTab('settings-tab')">
                    <v-list-item-icon>
                        <v-icon>mdi-cog-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $t('settings.settingsTabTitle') }}
                    </v-list-item-title>
                    <v-list-item-action>
                        <v-icon>
                            mdi-chevron-right
                        </v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item @click="updateTab('appearance-tab')">
                    <v-list-item-icon>
                        <v-icon>mdi-palette-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $t('settings.appearanceTabTitle') }}
                    </v-list-item-title>
                    <v-list-item-action>
                        <v-icon>
                            mdi-chevron-right
                        </v-icon>
                    </v-list-item-action>
                </v-list-item>
                <v-list-item @click="updateTab('notification-tab')">
                    <v-list-item-icon>
                        <v-icon>mdi-bell-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ $t('settings.notificationTabTitle') }}
                    </v-list-item-title>
                    <v-list-item-action>
                        <v-icon>
                            mdi-chevron-right
                        </v-icon>
                    </v-list-item-action>
                </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
        <v-divider v-if="$vuetify.breakpoint.xs"></v-divider>
    </v-container>
</template>

<script>
export default {
    name: "settings-menu-component",
    data() {
        return {
            tab: "account-tab"
        }
    },
    methods: {
        updateTab(tab) {
            if (tab != this.tab){
                this.tab = tab
                this.$emit("updateTab", tab)
            }
        }
    }
}
</script>

<style scoped>
.v-list-item--active {
  /* Accent color */
    color: var(--v-accent-base);
}
</style>