<template>
  <v-container fluid>
    <!--
    <v-row v-if="$vuetify.breakpoint.xs" no-gutters>
      <v-spacer />
      <v-col>
        <sync-addresses-button :addresses="getAddressesToSync" :text="$t('address.syncAllAddressesButton')"
          @successfulSync="fetchData"></sync-addresses-button>
      </v-col>
      <v-spacer />

    </v-row>
    -->
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-col>
        <v-row v-if="$vuetify.breakpoint.lgAndUp">
          <overview-card
            :balances="getBlockchainTypeBalances"
            balanceType="BLOCKCHAIN"
            :maxLimit="5"
          ></overview-card>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.md">
          <overview-card
            :balances="getBlockchainTypeBalances"
            balanceType="BLOCKCHAIN"
            :maxLimit="3"
          ></overview-card>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.sm">
          <overview-card
            :balances="getBlockchainTypeBalances"
            balanceType="BLOCKCHAIN"
            :maxLimit="2"
          ></overview-card>
        </v-row>
        <v-row>
          <v-col xl="4" lg="4" md="4" sm="6" xs="12">
            <address-select
              @addressSelected="updateAddresses"
              :addresses="addresses"
            ></address-select>
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="6" xs="12">
            <blockchain-select
              @blockchainTypeSelected="updateBlockchainTypes"
            ></blockchain-select>
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="6" xs="12">
            <tag-select @tagSelected="updateTags" :tags="tags"></tag-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <overview-tabs
              :addresses="getAddresses"
              :blockchainTypes="blockchainTypes"
            ></overview-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="$vuetify.breakpoint.xs">
      <v-row>
        <v-col>
          <overview-card
            :balances="getBlockchainTypeBalances"
            balanceType="BLOCKCHAIN"
            :maxLimit="3"
          ></overview-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <address-select
            @addressSelected="updateAddresses"
            :addresses="addresses"
          ></address-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <blockchain-select
            @blockchainTypeSelected="updateBlockchainTypes"
          ></blockchain-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <tag-select @tagSelected="updateTags" :tags="tags"></tag-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <overview-tabs
            :addresses="getAddresses"
            :blockchainTypes="blockchainTypes"
          ></overview-tabs>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
//import AddressAvatarComponent from '../../components/misc/AddressAvatarComponent.vue';
import OverviewCard from "../../components/card/OverviewCard.vue";
import OverviewTabs from "../../components/tabs/OverviewTabs.vue";
//import SyncAddressesButton from '../../components/button/SyncAddressesButton.vue';
//import RouterButton from "../../components/button/RouterButton.vue";
import AddressSelect from "../../components/select/AddressSelect.vue";
import TagSelect from "../../components/select/TagSelect.vue";
import BlockchainSelect from "../../components/select/BlockchainSelect.vue";
//import AddressRouterSelect from "../../components/select/AddressRouterSelect.vue";
export default {
  name: "user-overview-page",
  components: {
    //AddressAvatarComponent,
    OverviewCard,
    OverviewTabs,
    //SyncAddressesButton,
    //RouterButton,
    AddressSelect,
    TagSelect,
    BlockchainSelect,
    //AddressRouterSelect,
  },
  data() {
    return {
      selectedAddresses: [],
      selectedTags: [],
      blockchainTypes: [],
    };
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapFields("userAddresses", ["addresses"]),
    ...mapFields("userTags", ["tags"]),
    ...mapFields("addressBalance", ["balances"]),

    getBlockchainTypeBalances() {
      return Object.values(
        this.balances.reduce((agg, bal) => {
          if (agg[bal.blockchainType] === undefined)
            agg[bal.blockchainType] = {
              blockchainType: bal.blockchainType,
              value: 0,
            };
          agg[bal.blockchainType].value += +bal.value;
          return agg;
        }, {})
      );
    },
    getAssetTypeBalances() {
      return Object.values(
        this.balances.reduce((agg, bal) => {
          if (agg[bal.assetType] === undefined)
            agg[bal.assetType] = { assetType: bal.assetType, value: 0 };
          agg[bal.assetType].value += +bal.value;
          return agg;
        }, {})
      );
    },
    getAddressBalances() {
      return Object.values(
        this.balances.reduce((agg, bal) => {
          if (agg[bal.address] === undefined)
            agg[bal.address] = { address: bal.address, value: 0 };
          agg[bal.address].value += +bal.value;
          return agg;
        }, {})
      );
    },
    getAddresses() {
      let selectedUserAddresses = [];
      if (this.selectedAddresses.length > 0 && this.selectedTags.length > 0) {
        selectedUserAddresses = this.addresses.filter((userAddress) => {
          return (
            this.selectedAddresses.includes(userAddress.address.address) &&
            userAddress.tag != null &&
            this.selectedTags.includes(userAddress.tag.id)
          );
        });
      } else if (this.selectedAddresses.length > 0) {
        selectedUserAddresses = this.addresses.filter((userAddress) => {
          return this.selectedAddresses.includes(userAddress.address.address);
        });
      } else if (this.selectedTags.length > 0) {
        selectedUserAddresses = this.addresses.filter((userAddress) => {
          return (
            userAddress.tag != null &&
            this.selectedTags.includes(userAddress.tag.id)
          );
        });
      } else {
        selectedUserAddresses = this.addresses;
      }
      return selectedUserAddresses.map(
        (selectedUserAddress) => selectedUserAddress.address.address
      );
    },
    getAddressesToSync() {
      return this.addresses
        .filter((userAddress) => userAddress.userAddressType === "PERSONAL")
        .map((personalUserAddress) => personalUserAddress.address.address);
    },
  },
  methods: {
    ...mapActions("userAddresses", ["fetchPersonalUserAddresses"]),
    ...mapActions("userTags", ["fetchTags"]),
    ...mapActions("addressBalance", ["fetchUserAddressesBalances"]),
    updateAddresses(newAddresses) {
      if (!newAddresses) {
        return;
      }
      this.selectedAddresses = newAddresses;
    },
    updateTags(newTags) {
      if (!newTags) {
        return;
      }
      this.selectedTags = newTags;
    },
    updateBlockchainTypes(newBlockchainTypes) {
      if (!newBlockchainTypes) {
        return;
      }
      this.blockchainTypes = newBlockchainTypes;
    },
    fetchData() {
      this.fetchPersonalUserAddresses();
    },
  },
  mounted() {
    this.fetchPersonalUserAddresses();
    this.fetchTags();
    this.fetchUserAddressesBalances();
  },
};
</script>

<style></style>