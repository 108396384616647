var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',{staticClass:"pa-3",attrs:{"value":"tab-history"}},[_c('paged-data-table',{attrs:{"namespace":_vm.getNamespace,"itemEndpoint":_vm.getTableEndpoint,"itemsHeaders":_vm.getHeaders,"itemKey":"id","sortBy":"created","sortDesc":true,"query":_vm.getSearchQuery,"isCard":false},on:{"clickOnRow":_vm.handleClickOnRow},scopedSlots:_vm._u([{key:"item.blockchainType",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"src":require(("../../assets/blockchain/" + (item.blockchainType) + ".svg")),"alt":"","width":"25","aspect-ratio":"1","contain":""}})]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("PersonalAddressFormatter")(item.address))+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("DateFormatter")(item.created))+" ")]}},{key:"item.transactionActionType",fn:function(ref){
var item = ref.item;
return [(item.transactionActionType != 'UNIDENTIFIED')?[_c('v-icon',[_vm._v(_vm._s(_vm._f("TransactionActionTypeIconFormatter")(item.transactionActionType)))]),_c('span',[_vm._v(_vm._s(_vm._f("TransactionActionTypeStringFormatter")(item.transactionActionType)))])]:_vm._e()]}},{key:"item.received",fn:function(ref){
var item = ref.item;
return [_c('received-sent-component',{staticClass:"my-1",attrs:{"movements":item.received}})]}},{key:"item.sent",fn:function(ref){
var item = ref.item;
return [_c('received-sent-component',{staticClass:"my-1",attrs:{"movements":item.sent}})]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [(item.fee > 0)?_c('gas-value-component',{attrs:{"blockchainType":item.feeMovement.coin.symbol,"quantity":item.fee,"value":_vm.getFeeCurrencyValue(item)}}):_vm._e()]}}],null,true)}),_c('transaction-detail-dialog',{attrs:{"dialog":_vm.dialog,"transaction":_vm.selectedTransaction,"address":_vm.selectedAddress},on:{"update:dialog":function($event){_vm.dialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }