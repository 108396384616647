var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('router-button',{attrs:{"text":_vm.$t('connection.addConnectionButton'),"redirect":"/add-connection"}})],1)],1)],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.xs)?[_c('v-row',{staticClass:"mb-1",attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-col',[_c('router-button',{attrs:{"text":_vm.$t('connection.addConnectionButton'),"redirect":"/add-connection"}})],1),_c('v-spacer')],1)]:_vm._e(),_c('v-row',[_c('v-col',[_c('paged-data-table',{attrs:{"namespace":_vm.userAddressesNamespace,"itemEndpoint":"/userAddress/search","itemsHeaders":_vm.userAddressesHeaders,"itemKey":"id","refresh":_vm.refresh,"sortBy":"name","sortDesc":false},on:{"dataFetched":_vm.onDataFetched},scopedSlots:_vm._u([{key:"item.userAddressType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("UserAddressTypeFormatter")(item.userAddressType))+" ")]}},{key:"item.address",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"align":'center'}},[_vm._v(" "+_vm._s(_vm._f("AddressFormatter")(item.address.address))+" "),_c('copy-address-button',{attrs:{"address":item.address.address}})],1)]}},{key:"item.tag",fn:function(ref){
var item = ref.item;
return [(item.tag)?[_c('tag-chip',{attrs:{"tag":item.tag}})]:[_vm._v(" - ")]]}},{key:"item.address.updated",fn:function(ref){
var item = ref.item;
return [_c('address-status-component',{attrs:{"address":item.address},on:{"successfulSync":_vm.fetchData}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('update-address-button',{attrs:{"userAddress":item},on:{"addressUpdated":_vm.fetchData}}),_c('delete-address-button',{attrs:{"address":item.address.address},on:{"addressDeleted":_vm.fetchData}})],1)]}}],null,true)})],1)],1),_c('v-row',[(_vm.$vuetify.breakpoint.xs)?_c('v-spacer'):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("externalData.externalDataTitle")))])]),(_vm.$vuetify.breakpoint.xs)?_c('v-spacer'):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.xs)?_c('v-spacer'):_vm._e(),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.$t("externalData.externalDataDescription"))+" ")]),(_vm.$vuetify.breakpoint.xs)?_c('v-spacer'):_vm._e()],1),_c('v-row',[_c('v-col',[_c('external-data-table',{attrs:{"refresh":_vm.refresh},on:{"dataFetched":function($event){_vm.refresh = false},"dataDeleted":_vm.fetchData,"dataUpdated":_vm.fetchData}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }