<template>
  <paged-data-table
    :namespace="namespace"
    :itemEndpoint="endpoint"
    :itemsHeaders="getHeaders"
    itemKey="id"
    sortBy="created"
    :sortDesc="true"
    :refresh="refresh"
    @dataFetched="onDataFetched"
  >
    <template v-slot:[`item.created`]="{ item }">
      {{ item.created | DateFormatter }}
    </template>
    <template v-slot:[`item.exchangeType`]="{ item }">
      <blockchain-type-component :blockchainType="item.exchangeType" />
    </template>
    <template v-slot:[`item.transactionNumber`]="{ item }">
      {{ item.transactionNumber ? item.transactionNumber : "-" }}
    </template>
    <template v-slot:[`item.firstTransaction`]="{ item }">
      {{
        item.firstTransaction
          ? $options.filters.DateFormatter(item.firstTransaction)
          : "-"
      }}
    </template>
    <template v-slot:[`item.lastTransaction`]="{ item }">
      {{
        item.lastTransaction
          ? $options.filters.DateFormatter(item.lastTransaction)
          : "-"
      }}
    </template>
    <template v-slot:[`item.documentStatusType`]="{ item }">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" :color="getStatusColor(item.documentStatusType)">{{
            getStatusIcon(item.documentStatusType)
          }}</v-icon>
        </template>
        <span v-if="item.documentStatusType != 'ERROR'">{{
          item.documentStatusType | DocumentStatusFormatter
        }}</span>
        <span v-else>{{
          item.errorType | ExternalDataErrorTypeFormatter
        }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.statusDescription`]="{ item }">
      <span v-if="item.documentStatusType != 'ERROR'">{{
        item.documentStatusType | DocumentStatusFormatter
      }}</span>
      <span v-else>{{ item.errorType | ExternalDataErrorTypeFormatter }}</span>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-row :align="'center'" :justify="'center'">
        <v-col cols="12" class="d-flex" :align="'center'" :justify="'center'">
          <update-external-data-button
            :document="item"
            @dataUpdated="onDataUpdated"
          ></update-external-data-button>
          <delete-external-data-button
            :documentId="item.id"
            @dataDeleted="onDataDeleted"
            class="mx-1"
          ></delete-external-data-button>
          <template v-if="item.fileId != null">
            <file-download
              :fileId="item.fileId"
              :fileSize="null"
              :fileMimeType="item.fileMimeType"
              :fileName="item.fileName"
              class="mx-1"
            />
          </template>
        </v-col>
      </v-row>
    </template>
  </paged-data-table>
</template>

<script>
import FileDownload from "../misc/FileDownload.vue";
import PagedDataTable from "./PagedDataTable.vue";
import BlockchainTypeComponent from "../misc/BlockchainTypeComponent.vue";
import DeleteExternalDataButton from "../button/DeleteExternalDataButton.vue";
import UpdateExternalDataButton from "../button/UpdateExternalDataButton.vue";
export default {
  components: {
    PagedDataTable,
    FileDownload,
    BlockchainTypeComponent,
    DeleteExternalDataButton,
    UpdateExternalDataButton,
  },
  name: "external-data-table",
  props: {
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      namespace: "external-sources",
      endpoint: "/document/external/search",
    };
  },
  computed: {
    getHeaders() {
      return [
        { text: this.$t("label.updated"), value: "created" },
        { text: this.$t("label.name"), value: "name" },
        { text: this.$t("label.address"), value: "exchangeType" },
        //{ text: this.$t('label.transactionNumber'), value: "numberOfTransactions" },
        { text: this.$t("label.firstTransaction"), value: "firstTransaction" },
        { text: this.$t("label.lastTransaction"), value: "lastTransaction" },
        {
          text: this.$t("label.status"),
          value: "documentStatusType",
          sortable: false,
        },
        {
          text: this.$t("label.statusDescription"),
          value: "statusDescription",
          sortable: false,
          width: "400px",

        },
        { value: "actions", sortable: false },
      ];
    },
  },
  methods: {
    getStatusIcon(status) {
      if (status == "SUCCESS" || status == "WARNING") {
        return "mdi-check-circle";
      } else if (status == "MANUAL_APPROVAL") {
        return "mdi-alert";
      } else if (status == "PENDING" || status == "PROCESSING") {
        return "mdi-sync-circle";
      } else {
        return "mdi-alert-circle";
      }
    },
    getStatusColor(status) {
      if (status == "SUCCESS" || status == "WARNING") {
        return "success";
      } else if (status == "MANUAL_APPROVAL") {
        return "warning";
      } else if (status == "PENDING" || status == "PROCESSING") {
        return "info";
      } else {
        return "error";
      }
    },
    onDataFetched(success) {
      if (success) {
        this.$emit("dataFetched", true);
      }
    },
    onDataDeleted(success) {
      if (success) {
        this.$emit("dataDeleted", true);
      }
    },
    onDataUpdated(success) {
      if (success) {
        this.$emit("dataUpdated", true);
      }
    },
  },
};
</script>

<style scoped>
.custom-tooltip {
  opacity: var(--v-tooltip-opacity, 1) !important;
  background: var(--v-tooltip-bg, rgb(109, 109, 109)) !important;
}
</style>