<template>
  <v-container fluid>
    <v-banner class="mb-5 settings-banner" sticky app outlined :value="userChanged">
      <v-row :align="'center'" justify="center">
        <v-col cols="auto">
            <v-icon size="30" color="warning"> mdi-alert-outline </v-icon>
        </v-col>
        <v-col cols="auto" :align="'center'" justify="start">
          {{ $t("user.updateUserAlertText") }}
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.xs" :align="'end'" justify="end">
          <v-btn :loading="loading" text @click="resetCurrentUser">
            {{ $t("user.updateUserCancelButton") }}
          </v-btn>
          <v-btn :loading="loading" text @click="updateUserSettings">
            {{ $t("user.updateUserConfirmButton") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.xs" :align="'center'" justify="center">
        <v-col :align="'center'" justify="center">
          <v-btn :loading="loading" text @click="resetCurrentUser">
            {{ $t("user.updateUserCancelButton") }}
          </v-btn>
          <v-btn :loading="loading" text @click="updateUserSettings">
            {{ $t("user.updateUserConfirmButton") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-banner>
    <v-row>
      <v-col cols="12" md="4">
        <settings-menu-component @updateTab="changeTab" />
      </v-col>

      <v-col cols="12" md="8">
        <settings-tabs :tab="tab" :newUser="newUser" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SettingsMenuComponent from "../../components/misc/SettingsMenuComponent.vue";
import SettingsTabs from "../../components/tabs/SettingsTabs.vue";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
const _ = require("lodash");
export default {
  components: { SettingsMenuComponent, SettingsTabs },
  name: "user-settings-page",
  data() {
    return {
      loading: false,
      newUser: {},
      tab: "account-tab",
    };
  },
  computed: {
    ...mapFields("loggedUser", { user: "loggedUser" }),
    userChanged() {
      return !_.isEqual(
        _.omit(this.user, ["pricingPlan"]),
        _.omit(this.newUser, ["pricingPlan"])
      );
    },
  },
  methods: {
    ...mapActions("loggedUser", ["updateUserData"]),
    ...mapActions("alert", ["createAlert"]),
    changeTab(tab) {
      this.tab = tab;
    },
    updateUserSettings() {
      this.loading = true;
      this.updateUserData(this.newUser)
        .then(() => {
          this.createAlert({
            message: this.$t("message.updateUserSuccess"),
            type: "success",
          });
        })
        .catch(() => {
          this.createAlert({
            message: this.$t("message.updateUserError"),
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetCurrentUser() {
      this.newUser = _.cloneDeep(this.user);
    },
  },
  created() {
    this.resetCurrentUser();
  },
};
</script>

<style scoped>
.settings-banner {
    background-color: var(--v-background-base) !important; /* Ensures the banner has a solid background */
}
</style>