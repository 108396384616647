import { render, staticRenderFns } from "./PricingPlansPage.vue?vue&type=template&id=36f9d0e7&scoped=true&"
import script from "./PricingPlansPage.vue?vue&type=script&lang=js&"
export * from "./PricingPlansPage.vue?vue&type=script&lang=js&"
import style0 from "./PricingPlansPage.vue?vue&type=style&index=0&id=36f9d0e7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36f9d0e7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCol,VContainer,VIcon,VImg,VRow,VSelect,VSpacer})
