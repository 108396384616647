<template>
    <v-card class="failure-card">
      <v-card-text>
          <v-row :align="'center'" :justify="'center'" class="my-10">
              <h2 class="primary--text">{{ $t('plan.paymentCancelTitle').toUpperCase() }}</h2>
          </v-row>
          <v-row :align="'center'" :justify="'center'" class="my-10">
              <v-icon size="48" color="error">mdi-alert-circle-outline</v-icon>
          </v-row>
          <v-row class="my-10">
              <v-col :align="'center'" :justify="'center'">
                   <div class="payment-cancelled-description">
                       {{ $t('plan.paymentCancelDescription') }}
                   </div>
              </v-col>
          </v-row>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  export default {
      name: 'payment-cancelled-card',
      components: {
      },
  }
  </script>
  
  <style>
  .failure-card {
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
      max-width: 400px;
      margin: auto;
      padding: 20px;
  }
  
  .primary--text {
      color: #f44336;
      font-size: 24px;
      margin: 0;
  }
  
  .payment-cancelled-description {
      font-size: 18px;
      line-height: 1.5;
      text-align: center;
  }
  
  .mx-3 {
      margin-left: 3px;
      margin-right: 3px;
  }
  </style>