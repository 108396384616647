export const getCurrencySymbolFromString = (value) => {
    let symbol
    switch (value) {
        case "USD":
            symbol = "$"
            break
        case "EUR":
            symbol = "€"
            break
        case "BTC":
            symbol = "BTC"
            break
        case "ETH":
            symbol = "ETH"
            break
        default:
            symbol = value
            break
    }
    return symbol
}

export const truncateString = (value, length) => {
    if (value.length > length) {
        return value.substring(0, length) + "..."
    }
    return value
}

export const getSpaceFromCurrencySymbol = (value) => {
    let space
    switch (value) {
        case "$":
        case "€":
            space = "";
            break
        default:
            space = " ";
            break
    }
    return space;
}

export const getCurrencyFromBlockchainType = (value) => {
    // BLOCKCHAIN_TYPE: add here all supported blockchain types symbol mapping
    let symbol
    switch (value) {
        case "BITCOIN":
            symbol = "BTC"
            break
        case "ETHEREUM":
        case "ARBITRUM":
        case "AURORA":
        case "OPTIMISM":
        case "BLAST":
        case "BASE":
        case "ARBITRUM_NOVA":
        case "POLYGONZK":
        case "ZKSYNC":  
        case "BOBA":    
            symbol = "ETH"
            break
        case "SOLANA":
            symbol = "SOL"
            break    
        case "BSC":
            symbol = "BNB"
            break
        case "POLYGON":
            symbol = "MATIC"
            break
        case "AVALANCHE":
            symbol = "AVAX"
            break
        case "FANTOM":
            symbol = "FTM"
            break
        case "HECO":
            symbol = "HC"
            break
        case "MOONRIVER":
            symbol = "MOVR"
            break
        case "MOONBEAM":
            symbol = "GLMR"
            break
        case "KLAYTN":
            symbol = "KLAY"
            break
        case "CRONOS":
            symbol = "CRO"
            break
        case "XDAI":
            symbol = "xDAI"
            break    
        case "CELO":
            symbol = "CELO"
            break
        case "HARMONY":
            symbol = "ONE"
            break
        case "CLV":
            symbol = "CLV"
            break    
        default:
            symbol = value
            break
    }
    return symbol
}

export const getDecimalsFromBlockchainType = (value) => {
    // BLOCKCHAIN_TYPE: add here all supported blockchain types for blockchain coin decimals
    let decimals
    switch (value) {
        case "BITCOIN":
            decimals = 8
            break
        case "ETHEREUM":
        case "BSC":
        case "POLYGON":
        case "AVALANCHE":
        case "ARBITRUM":
        case "FANTOM":
        case "AURORA":
        case "HECO":
        case "MOONRIVER":
        case "MOONBEAM":
        case "KLAYTN":
        case "CRONOS":
        case "OPTIMISM":
        case "BLAST":
        case "BASE":
        case "ARBITRUM_NOVA":
        case "POLYGONZK":
        case "ZKSYNC":  
        case "XDAI":    
        case "CELO":
        case "HARMONY":
        case "BOBA":
        case "CLV":
            decimals = 18
            break
        case "SOLANA":
            decimals = 9
            break    
        default:
            decimals = 18
            break
    }
    return decimals
}

export const getCurrencyDecimalsFromString = (value) => {
    let decimals
    switch (value) {
        case "USD":
        case "EUR":
        case "xDAI":    
            decimals = 2
            break
        case "BTC":
            decimals = 5
            break
        default:
            decimals = 4
            break
    }
    return decimals
}

export const getBlockchainTypeFromString = (value) => {
    // BLOCKCHAIN_TYPE: add here all supported blockchain types string mapping
    let string
    switch (value) {
        case "BITCOIN":
            string = "Bitcoin"
            break
        case "ETHEREUM":
            string = "Ethereum"
            break
        case "SOLANA":
            string = "Solana"
            break    
        case "POLYGON":
            string = "Polygon"
            break
        case "BSC":
            string = "BSC"
            break
        case "AVALANCHE":
            string = "Avalanche"
            break
        case "ARBITRUM":
            string = "Arbitrum"
            break
        case "FANTOM":
            string = "Fantom"
            break
        case "AURORA":
            string = "Aurora"
            break
        case "HECO":
            string = "Heco"
            break
        case "MOONRIVER":
            string = "Moonriver"
            break
        case "MOONBEAM":
            string = "Moonbeam"
            break
        case "KLAYTN":
            string = "Klaytn"
            break
        case "CRONOS":
            string = "Cronos"
            break
        case "OPTIMISM":
            string = "Optimism"
            break
        case "XDAI":
            string = "Gnosis"
            break    
        case "CELO":
            string = "Celo"
            break
        case "HARMONY":
            string = "Harmony"
            break
        case "BOBA":
            string = "Boba"
            break  
        case "CLV":
            string = "Clover"
            break
        case "BLAST":
            string = "Blast"
            break
        case "BASE":
            string = "Base"
            break
        case "ARBITRUM_NOVA":
            string = "Arbitrum Nova"
            break
        case "POLYGONZK":
            string = "Polygon zkEVM"
            break
        case "ZKSYNC":
            string = "zkSync"
            break
        case "BINANCE":
            string = "Binance"
            break
        case "COINBASE":
            string = "Coinbase"
            break
        case "KUCOIN":
            string = "Kucoin"
            break
        case "GEMINI":
            string = "Gemini"
            break
        case "THEROCKTRADING":
            string = "The Rock Trading"
            break
        case "KRAKEN":
            string = "Kraken"
            break
        case "BITSTAMP":
            string = "Bitstamp"
            break
        case "BITTREX":
            string = "Bittrex"
            break
        case "UPBIT":
            string = "Upbit"
            break
        case "OKX":
            string = "OKX"
            break
        case "HUOBI":
            string = "Huobi"
            break
        case "GATEIO":
            string = "Gate.io"
            break
        case "FTX":
            string = "FTX"
            break
        case "DIGIFINEX":
            string = "DigiFinex"
            break
        case "CEXIO":
            string = "CEX.IO"
            break
        case "BITHUMB":
            string = "Bithumb"
            break
        case "BITFINEX":
            string = "Bitfinex"
            break  
        case "CRYPTOCOM":
            string = "Crypto.com App"
            break
        case "CRYPTOCOMEXCHANGE":
            string = "Crypto.com Exchange"
            break    
        case "YOUNGPLATFORM":
            string = "Young Platform"
            break
        case "BITPANDA":
            string = "Bitpanda"
            break    
        case "UPHOLD":
            string = "Uphold"
            break
        case "LEDGERCSV":
            string = "Ledger CSV"
            break   
        case "KRIPTOMAT":
            string = "Kriptomat"
            break    
        case "ONETRADING":
            string = "One Trading"
            break
        case "BITGET":
            string = "Bitget"
            break    
        case "RAYN":
            string = "Rayn"
            break
        case "CONIO":
            string = "Conio"
            break
        case "SORARE":
            string = "Sorare"
            break    
        case "POLONIEX":
            string = "Poloniex"
            break
        case "NEXO":
            string = "Nexo"
            break
        case "BYBIT":
            string = "Bybit"
            break      
        case "BIBOX":
            string = "Bibox"
            break
        case "COINTRACKING":
            string = "CoinTracking"
            break
        case "REVOLUT":
            string = "Revolut"
            break
        case "CRYPTOSMART":
            string = "Cryptosmart"
            break
        case "STAKETAX":
            string = "StakeTax"
            break
        case "ALGORAND":
            string = "Algorand"
            break
        case "COSMOS":
            string = "Cosmos"
            break
        case "TERRACLASSIC":
            string = "Terra Classic"
            break
        case "TERRA":
            string = "Terra"
            break                                                               
        default:
            string = value
            break
    }
    return string
}

export const getWalletTypeFromString = (value) => {
    let string
    switch (value) {
        case "METAMASK":
            string = "Metamask"
            break
        case "SAFEPAL":
            string = "SafePal"
            break
        case "BITBOX":
            string = "BitBox"
            break    
        case "BITGET_WALLET":
            string = "Bitget Wallet"
            break
        case "COINBASE_WALLET":
            string = "Coinbase Wallet"
            break
        case "CRYPTOCOM_WALLET":
            string = "Crypto.com DeFi Wallet"
            break             
        case "PHANTOM":
            string = "Phantom"
            break
        case "EXODUS":
            string = "Exodus"
            break
        case "TREZOR":
            string = "Trezor"
            break
        case "TREZOR_BITCOIN":
            string = "Trezor (Bitcoin)"
            break    
        case "LEDGER":
            string = "Ledger"
            break
        case "LEDGER_BITCOIN":
            string = "Ledger (Bitcoin)"
            break         
        case "TRUSTWALLET":
            string = "Trust Wallet"
            break                                       
        default:
            string = value
            break
    }
    return string
}

export const getPlatformTypeFromString = (value) => {
    let string
    switch (value) {
        case "COINTRACKING":
            string = "CoinTracking"
            break
        case "REVOLUT":
            string = "Revolut"
            break
        case "RAYN":
            string = "Rayn"
            break
        default:
            string = value
            break
    }
    return string;
}
export const checkAddressType = (address) => {
    // Regular expressions for EVM, Bitcoin, and Solana addresses
    const EVMAddressPattern = /^0x[a-fA-F0-9]{40}$/i;
    const BitcoinAddressPattern = /^(?:[13]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|bc1[a-z0-9]{39,59})$/i;
    const SolanaAddressPattern = /^([1-9A-HJ-NP-Za-km-z]{32,44})$/i;
    const xpubPattern = /^xpub[a-km-zA-HJ-NP-Z0-9]{107}$/;
    const ypubPattern = /^ypub[a-km-zA-HJ-NP-Z0-9]{107}$/;
    const zpubPattern = /^zpub[a-km-zA-HJ-NP-Z0-9]{107}$/;
    if (EVMAddressPattern.test(address)) {
        return 'ETHEREUM';
      } else if (BitcoinAddressPattern.test(address) || xpubPattern.test(address) || ypubPattern.test(address) || zpubPattern.test(address)) {
        return 'BITCOIN';
      } else if (SolanaAddressPattern.test(address)) {
        return 'SOLANA';
      } else {
        return null;
      }
}

export const getScanBaseUrlFromBlockchainType = (value) => {
    // BLOCKCHAIN_TYPE: add here all supported blockchain for scan base url
    let string
    switch (value) {
        case "ETHEREUM":
            string = "https://etherscan.io/"
            break
        case "POLYGON":
            string = "https://polygonscan.com/"
            break
        case "SOLANA":
            string = "https://solscan.io/"  
            break
        case "BSC":
            string = "https://bscscan.com/"
            break
        case "AVALANCHE":
            string = "https://snowtrace.io/"
            break
        case "ARBITRUM":
            string = "https://arbiscan.io/"
            break
        case "FANTOM":
            string = "https://ftmscan.com/"
            break
        case "AURORA":
            string = "https://aurorascan.dev/"
            break
        case "HECO":
            string = "https://hecoinfo.com/"
            break
        case "MOONRIVER":
            string = "https://moonriver.moonscan.io/"
            break
        case "MOONBEAM":
            string = "https://moonbeam.moonscan.io/"
            break
        case "KLAYTN":
            string = "https://scope.klaytn.com/"
            break
        case "CRONOS":
            string = "https://cronoscan.com/"
            break
        case "OPTIMISM":
            string = "https://optimistic.etherscan.io/"
            break
        case "XDAI":
            string = "https://gnosisscan.io/"
            break    
        case "CELO":
            string = "https://celoscan.xyz/"
            break
        case "HARMONY":
            string = "https://explorer.harmony.one/"
            break
        case "BOBA":
            string = "https://bobascan.com/"
            break
        case "CLV":
            string = "https://clvscan.com/"
            break
        case "BLAST":
            string = "https://blastscan.io/"
            break
        case "BASE":
            string = "https://basescan.org/"
            break
        case "ARBITRUM_NOVA":
            string = "https://nova.arbiscan.io/"
            break
        case "POLYGONZK":
            string = "https://zkevm.polygonscan.com/"
            break
        case "ZKSYNC":
            string = "https://era.zksync.network/"
            break
        case "BITCOIN":
            string = "https://www.blockchain.com/"
            break
        case "CRYPTOORG":
            string = "https://crypto.org/explorer/"
            break             
        default:
            string = ""
            break
    }
    return string
}

export const getGroupValueFromTransactionActionType = (value) => {
    let string
    switch (value) {
        case "ERROR":
            string = "ERROR"
            break
        case "CONTRACT_INTERACTION":    
        case "GENERIC_CONTRACT_INTERACTION":
        case "OPENSEA_CONTRACT_INTERACTION":
            string = "CONTRACT_INTERACTION"
            break
        case "SEND_YOURSELF_NOTHING":
        case "SEND_YOURSELF_BLOCKCHAIN_COIN":
        case "SEND_YOURSELF_ERC721":
        case "SEND_YOURSELF_ERC1155":
        case "SEND_YOURSELF_ERC20":
        case "SELF":    
            string = "SELF"
            break
        case "SEND_BLOCKCHAIN_COIN_WITH_TRANSFER":
        case "SEND_ERC721":
        case "SEND_ERC1155":
        case "SEND_ERC20":
        case "SEND_CRYPTO_NOT_TAXABLE":
        case "WITHDRAW_CRYPTO_TO_ADDRESS":
        case "WITHDRAW_CRYPTO":
        case "SEND":                  
            string = "SEND"
            break
        case "WITHDRAW_FIAT_TO_BANK_ACCOUNT":
        case "WITHDRAW_FIAT_TO_CREDIT_CARD":
        case "WITHDRAW_FIAT":
        case "WITHDRAW":    
            string = "WITHDRAW"
            break     
        case "RECEIVE_BLOCKCHAIN_COIN_FROM_TRANSFER":
        case "RECEIVE_BLOCKCHAIN_COIN_FROM_CONTRACT":
        case "RECEIVE_ERC721":
        case "RECEIVE_ERC1155":
        case "RECEIVE_ERC20":
        case "RECEIVE_ERC721_WITH_CONTRACT":
        case "RECEIVE_ERC1155_WITH_CONTRACT":
        case "DEPOSIT_CRYPTO_FROM_ADDRESS":
        case "DEPOSIT_CRYPTO":
        case "RECEIVE":               
            string = "RECEIVE"
            break
        case "DEPOSIT_FIAT_FROM_BANK_ACCOUNT":
        case "DEPOSIT_FIAT_FROM_CREDIT_CARD":
        case "DEPOSIT_FIAT":
        case "DEPOSIT":    
            string = "DEPOSIT"
            break
        case "STAKE_BLOCKCHAIN_COIN":
        case "STAKE_ERC20":
        case "STAKE_ERC20_WITH_CONTRACT":
        case "STAKE":
            string = "STAKE"
            break
        case "UNSTAKE_BLOCKCHAIN_COIN":
        case "UNSTAKE_ERC20_WITH_CONTRACT":
        case "UNSTAKE_ERC20":
        case "UNSTAKE":    
            string = "UNSTAKE"
            break
        case "DEPOSIT_CRYPTO_FROM_REWARD":
        case "REWARD":    
            string = "REWARD"
            break    
        case "BURN_ERC20":
        case "BURN_ERC721":
        case "BURN_ERC1155":
        case "BURN":    
            string = "BURN"
            break
        case "WRAP_ETHER":
        case "WRAP":    
            string = "WRAP"
            break
        case "UNWRAP_ETHER":
        case "UNWRAP":    
            string = "UNWRAP"
            break
        case "AIRDROP_ERC721":
        case "AIRDROP_ERC1155":
        case "AIRDROP_ERC20":
        case "AIRDROP_ERC20_FROM_CONTRACT":
        case "DEPOSIT_CRYPTO_FROM_AIRDROP":
        case "AIRDROP_REVERTED":
        case "AIRDROP":    
            string = "AIRDROP"
            break
        case "MINT_ERC721_FREE":
        case "MINT_ERC721_WITH_BLOCKCHAIN_COIN":
        case "MINT_ERC721_WITH_ERC20":
        case "MINT_ERC1155_FREE":
        case "MINT_ERC1155_WITH_BLOCKCHAIN_COIN":
        case "MINT_ERC1155_WITH_ERC20":
        case "MINT":    
            string = "MINT"
            break
        case "BUY_ERC721_WITH_BLOCKCHAIN_COIN_OPENSEA":
        case "BUY_ERC721_WITH_BLOCKCHAIN_COIN_OPENSEA_WITH_REFUND":
        case "BUY_ERC721_WITH_ERC20_OPENSEA":
        case "BUY_ERC721_WITH_ERC20_OPENSEA_OFFER_ACCEPTED":
        case "BUY_ERC1155_WITH_BLOCKCHAIN_COIN_OPENSEA":
        case "BUY_ERC1155_WITH_BLOCKCHAIN_COIN_OPENSEA_WITH_REFUND":
        case "BUY_ERC1155_WITH_ERC20_OPENSEA":
        case "BUY_ERC1155_WITH_ERC20_OPENSEA_OFFER_ACCEPTED":
        case "BUY_CRYPTO_WITH_BANK_TRANSFER":
        case "BUY_CRYPTO_WITH_CASH_BALANCE":
        case "BUY_CRYPTO_WITH_CREDIT_CARD":
        case "BUY_CRYPTO_WITH_ONLINE_BANKING":
        case "BUY_CRYPTO_WITH_GOOGLE_PAY":
        case "BUY_CRYPTO_WITH_FIAT":
        case "BUY_CRYPTO_WITH_CREDIT_LINE":
        case "BUY_NFT":
        case "BUY":                           
            string = "BUY"
            break
        case "SELL_ERC721_WITH_BLOCKCHAIN_COIN_OPENSEA":
        case "SELL_ERC721_WITH_ERC20_OPENSEA":
        case "SELL_ERC721_WITH_ERC20_OPENSEA_ACCEPT_OFFER":
        case "SELL_ERC1155_WITH_BLOCKCHAIN_COIN_OPENSEA":
        case "SELL_ERC1155_WITH_ERC20_OPENSEA":
        case "SELL_ERC1155_WITH_ERC20_OPENSEA_ACCEPT_OFFER":
        case "SELL_CRYPTO_FOR_BANK_TRANSFER":
        case "SELL_CRYPTO_FOR_CASH_BALANCE":
        case "SELL_CRYPTO_FOR_CREDIT_CARD":
        case "SELL_CRYPTO_FOR_ONLINE_BANKING":
        case "SELL_CRYPTO_FOR_FIAT":
        case "SELL_NFT":
        case "SELL":                      
            string = "SELL"
            break
        case "CLAIM_ERC20_STAKING_REWARDS":
        case "CLAIM_ERC20_STAKING_REWARDS_WITH_CONTRACT":
        case "CLAIM_ERC20_FREE_WITH_CONTRACT":
        case "CLAIM_ERC20_FREE":
        case "MINT_ERC20_FREE":
        case "CLAIM":    
            string = "CLAIM"
            break
        case "SWAP_ERC20_ERC20":
        case "SWAP_ERC20_BLOCKCHAIN_COIN":
        case "SWAP_BLOCKCHAIN_COIN_ERC20":
        case "SWAP_BLOCKCHAIN_COIN_ERC20_WITH_REFUND":
        case "TRADE_CRYPTO_CRYPTO":
        case "CONVERT_DUST":
        case "CONVERT_CRYPTO":
        case "SWAP":
        case "TRADE_NFT":      
            string = "SWAP"
            break
        case "ADD_LIQUIDITY_ERC20_ERC20":
        case "ADD_LIQUIDITY":    
            string = "ADD_LIQUIDITY"
            break
        case "REMOVE_LIQUIDITY_ERC20_ERC20":
        case "REMOVE_LIQUIDITY":     
            string = "REMOVE_LIQUIDITY"
            break
        case "WITHDRAW_BLOCKCHAIN_COIN_FROM_POLYGON":
        case "WITHDRAW_ERC20_FROM_POLYGON":
        case "RECEIVE_ERC20_FROM_BRIDGE":
        case "BRIDGE_WITHDRAW":    
            string = "BRIDGE_WITHDRAW"
            break
        case "DEPOSIT_BLOCKCHAIN_COIN_TO_POLYGON":
        case "DEPOSIT_ERC20_TO_POLYGON":
        case "BRIDGE_DEPOSIT":    
            string = "BRIDGE_DEPOSIT"
            break
        case "UNIDENTIFIED":
            string = "UNIDENTIFIED"
            break
        case "REIMBURSEMENT_DEPOSIT":
        case "REIMBURSEMENT_DEPOSIT_TO_CREDIT_CARD":
        case "REIMBURSEMENT":    
            string = "REIMBURSEMENT"
            break
        case "CASHBACK_DEPOSIT":
        case "CASHBACK":    
            string = "CASHBACK"
            break
        case "REPAY_CREDIT_WITH_FIAT":
        case "REPAY_CREDIT_WITH_CRYPTO":
        case "REPAY_CREDIT":    
            string = "REPAY_CREDIT"
            break
        case "MARGIN_FEE":
        case "FEE":    
            string = "FEE"
            break
        case "SEND_FIAT_FROM_MARGIN":
        case "SEND_CRYPTO_FROM_MARGIN":
        case "MARGIN_LOSS":    
            string = "MARGIN_LOSS"
            break
        case "RECEIVE_FIAT_FROM_MARGIN":
        case "RECEIVE_CRYPTO_FROM_MARGIN":
        case "MARGIN_GAIN":    
            string = "MARGIN_GAIN"
            break
        case "FUTURE_PROFIT":
            string = "FUTURE_PROFIT"
            break
        case "FUTURE_LOSS":
            string = "FUTURE_LOSS"
            break
        case "REALIZED_PROFIT":
            string = "REALIZED_PROFIT"
            break
        case "REALIZED_LOSS":
            string = "REALIZED_LOSS"
            break 
        case "BORROW":
        case "BORROW_CRYPTO":
            string = "BORROW"
            break
        case "COLLATERAL_ADDED":
            string = "COLLATERAL_ADDED"
            break
        case "COLLATERAL_RETURNED":
            string = "COLLATERAL_RETURNED"
            break
        case "LEND":
            string = "LEND"
            break
        case "UNLEND":
            string = "UNLEND"
            break
        case "DONATION":
            string = "DONATION"
            break
        case "LOST":
            string = "LOST"
            break
        case "GIFT":
            string = "GIFT"
            break
        case "REPAY_LOAN":
            string = "REPAY_LOAN"
            break
        case "MINING":
            string = "MINING"
            break
        case "NOT_TAXABLE_EVENT":
            string = "NOT_TAXABLE_EVENT"
            break                         
        case "PAYMENT":
        case "PAYMENT_WITH_CREDIT_CARD":
            string = "PAYMENT"
            break
        case "REIMBURSEMENT_REVERTED":
        case "REIMBURSEMENT_REVERTED_TO_CREDIT_CARD":    
            string = "REIMBURSEMENT_REVERTED"
            break
        case "CASHBACK_REVERTED":
            string = "CASHBACK_REVERTED"
            break
        case "PAYMENT_REFUND":
            string = "PAYMENT_REFUND"
            break
        case "DEPOSIT_CRYPTO_FROM_INTEREST":
        case "LOAN_INTEREST":
        case "INTEREST":        
            string = "INTEREST"
            break                    
        default:
            string = value
            break
    }
    return string
}

export const getPossibleTransactionActionTypes = (numSent, numReceived) => {
    if (numSent === 0 && numReceived === 0) {
        return ["ERROR", "CONTRACT_INTERACTION", "SELF"]
    } else if (numSent === 0 && numReceived > 0) {
        return ["RECEIVE", "DEPOSIT", "UNSTAKE", "AIRDROP", "REWARD", "MINT", "CLAIM", "REMOVE_LIQUIDITY", "BRIDGE_WITHDRAW", "CASHBACK", "REIMBURSEMENT", "MARGIN_GAIN", "PAYMENT_REFUND", "INTEREST", "DONATION", "GIFT", "UNLEND", "BORROW", "MINING", "COLLATERAL_RETURNED"] 
    } else if (numSent > 0 && numReceived === 0) {
        return ["SEND", "WITHDRAW", "STAKE", "BURN", "ADD_LIQUIDITY", "BRIDGE_DEPOSIT", "MARGIN_LOSS", "REPAY_CREDIT", "PAYMENT", "FEE", "REPAY_LOAN", "LOST", "DONATION", "GIFT", "LEND", "REPAY_LOAN", "INTEREST", "FUTURE_LOSS", "COLLATERAL_ADDED", "AIRDROP"]
    } else {
        return ["SELF", "WRAP", "UNWRAP", "BUY", "SELL", "MINT", "BUY", "SELL", "SWAP", "ADD_LIQUIDITY", "REMOVE_LIQUIDITY", "FUTURE_PROFIT", "BORROW"]
    }

}

export const getTransactionActionTypesFromGroupValue = (value) => {
    let transactionActionTypes;
    switch (value) {
        case "ERROR":
            transactionActionTypes = ["ERROR"]
            break
        case "CONTRACT_INTERACTION":
            transactionActionTypes = ["GENERIC_CONTRACT_INTERACTION", "OPENSEA_CONTRACT_INTERACTION", "CONTRACT_INTERACTION"]
            break
        case "SELF":
            transactionActionTypes = ["SEND_YOURSELF_NOTHING", "SEND_YOURSELF_BLOCKCHAIN_COIN", "SEND_YOURSELF_ERC721", "SEND_YOURSELF_ERC1155", "SEND_YOURSELF_ERC20", "SELF"]
            break
        case "SEND":
            transactionActionTypes = ["SEND_BLOCKCHAIN_COIN_WITH_TRANSFER", "SEND_ERC721", "SEND_ERC1155", "SEND_ERC20", "SEND_CRYPTO_NOT_TAXABLE","WITHDRAW_CRYPTO_TO_ADDRESS", "WITHDRAW_CRYPTO", "SEND"]
            break
        case "WITHDRAW":
            transactionActionTypes = ["WITHDRAW_FIAT_TO_BANK_ACCOUNT", "WITHDRAW_FIAT_TO_CREDIT_CARD", "WITHDRAW_FIAT", "WITHDRAW"]    
            break
        case "RECEIVE":
            transactionActionTypes = ["RECEIVE_BLOCKCHAIN_COIN_FROM_TRANSFER", "RECEIVE_BLOCKCHAIN_COIN_FROM_CONTRACT", "RECEIVE_ERC721", "RECEIVE_ERC1155", "RECEIVE_ERC20", "RECEIVE_ERC721_WITH_CONTRACT", "RECEIVE_ERC1155_WITH_CONTRACT", "DEPOSIT_CRYPTO_FROM_ADDRESS", "DEPOSIT_CRYPTO", "RECEIVE"]
            break
        case "DEPOSIT":
            transactionActionTypes = ["DEPOSIT_FIAT_FROM_BANK_ACCOUNT", "DEPOSIT_FIAT_FROM_CREDIT_CARD", "DEPOSIT_FIAT", "DEPOSIT"]
            break
        case "STAKE":
            transactionActionTypes = ["STAKE_BLOCKCHAIN_COIN", "STAKE_ERC20", "STAKE_ERC20_WITH_CONTRACT", "STAKE"]
            break
        case "UNSTAKE":
            transactionActionTypes = ["UNSTAKE_BLOCKCHAIN_COIN", "UNSTAKE_ERC20", "UNSTAKE_ERC20_WITH_CONTRACT", "UNSTAKE"]
            break
        case "BURN":
            transactionActionTypes = ["BURN_ERC721", "BURN_ERC1155", "BURN_ERC20", "BURN"]
            break
        case "WRAP":
            transactionActionTypes = ["WRAP_ETHER", "WRAP"]
            break
        case "UNWRAP":
            transactionActionTypes = ["UNWRAP_ETHER", "UNWRAP"]
            break
        case "AIRDROP":
            transactionActionTypes = ["AIRDROP_ERC721", "AIRDROP_ERC1155", "AIRDROP_ERC20", "AIRDROP_ERC20_FROM_CONTRACT", "DEPOSIT_CRYPTO_FROM_AIRDROP", "AIRDROP", "AIRDROP_REVERTED"]
            break
        case "REWARD":
            transactionActionTypes = ["DEPOSIT_CRYPTO_FROM_REWARD", "REWARD"]
            break    
        case "MINT":
            transactionActionTypes = ["MINT_ERC721_FREE", "MINT_ERC1155_FREE", "MINT_ERC721_WITH_BLOCKCHAIN_COIN", "MINT_ERC1155_WITH_BLOCKCHAIN_COIN", "MINT_ERC721_WITH_ERC20", "MINT_ERC1155_WITH_ERC20", "MINT"]
            break
        case "BUY":
            transactionActionTypes = ["BUY_ERC721_WITH_BLOCKCHAIN_COIN_OPENSEA", "BUY_ERC721_WITH_BLOCKCHAIN_COIN_OPENSEA_WITH_REFUND", "BUY_ERC721_WITH_ERC20_OPENSEA", "BUY_ERC721_WITH_ERC20_OPENSEA_OFFER_ACCEPTED", "BUY_ERC1155_WITH_BLOCKCHAIN_COIN_OPENSEA", "BUY_ERC1155_WITH_BLOCKCHAIN_COIN_OPENSEA_WITH_REFUND", "BUY_ERC1155_WITH_ERC20_OPENSEA", "BUY_ERC1155_WITH_ERC20_OPENSEA_OFFER_ACCEPTED", "BUY_CRYPTO_WITH_BANK_TRANSFER", "BUY_CRYPTO_WITH_CASH_BALANCE", "BUY_CRYPTO_WITH_CREDIT_CARD", "BUY_CRYPTO_WITH_ONLINE_BANKING", "BUY_CRYPTO_WITH_GOOGLE_PAY", "BUY_CRYPTO_WITH_FIAT", "BUY_CRYPTO_WITH_CREDIT_LINE", "BUY_NFT", "BUY"]
            break
        case "SELL":
            transactionActionTypes = ["SELL_ERC721_WITH_BLOCKCHAIN_COIN_OPENSEA", "SELL_ERC721_WITH_ERC20_OPENSEA", "SELL_ERC721_WITH_ERC20_OPENSEA_ACCEPT_OFFER", "SELL_ERC1155_WITH_BLOCKCHAIN_COIN_OPENSEA", "SELL_ERC1155_WITH_ERC20_OPENSEA", "SELL_ERC1155_WITH_ERC20_OPENSEA_ACCEPT_OFFER", "SELL_CRYPTO_FOR_BANK_TRANSFER", "SELL_CRYPTO_FOR_CASH_BALANCE", "SELL_CRYPTO_FOR_CREDIT_CARD", "SELL_CRYPTO_FOR_ONLINE_BANKING", "SELL_CRYPTO_FOR_FIAT", "SELL_NFT", "SELL"]
            break
        case "CLAIM":
            transactionActionTypes = ["CLAIM_ERC20_STAKING_REWARDS", "CLAIM_ERC20_STAKING_REWARDS_WITH_CONTRACT", "CLAIM_ERC20_FREE_WITH_CONTRACT", "CLAIM_ERC20_FREE", "MINT_ERC20_FREE", "CLAIM"]
            break
        case "SWAP":
            transactionActionTypes = ["SWAP_ERC20_ERC20", "SWAP_ERC20_BLOCKCHAIN_COIN", "SWAP_BLOCKCHAIN_COIN_ERC20", "SWAP_BLOCKCHAIN_COIN_ERC20_WITH_REFUND", "TRADE_CRYPTO_CRYPTO", "CONVERT_DUST", "CONVERT_CRYPTO", "SWAP", "TRADE_NFT"]
            break
        case "ADD_LIQUIDITY":
            transactionActionTypes = ["ADD_LIQUIDITY_ERC20_ERC20", "ADD_LIQUIDITY"]
            break
        case "REMOVE_LIQUIDITY":
            transactionActionTypes = ["REMOVE_LIQUIDITY_ERC20_ERC20", "REMOVE_LIQUIDITY"]
            break
        case "BRIDGE_WITHDRAW":
            transactionActionTypes = ["WITHDRAW_BLOCKCHAIN_COIN_FROM_POLYGON", "WITHDRAW_ERC20_FROM_POLYGON", "BRIDGE_WITHDRAW"]
            break
        case "BRIDGE_DEPOSIT":
            transactionActionTypes = ["DEPOSIT_BLOCKCHAIN_COIN_TO_POLYGON", "DEPOSIT_ERC20_TO_POLYGON", "BRIDGE_DEPOSIT"]
            break
        case "UNIDENTIFIED":
            transactionActionTypes = ["UNIDENTIFIED"]
            break
        case "CASHBACK":
            transactionActionTypes = ["CASHBACK_DEPOSIT", "CASHBACK"]
            break
        case "REIMBURSEMENT":
            transactionActionTypes = ["REIMBURSEMENT_DEPOSIT", "REIMBURSEMENT_DEPOSIT_TO_CREDIT_CARD", "REIMBURSEMENT"]
            break
        case "CASHBACK_REVERTED":
            transactionActionTypes = ["CASHBACK_REVERTED"]
            break
        case "REIMBURSEMENT_REVERTED":
            transactionActionTypes = ["REIMBURSEMENT_REVERTED", "REIMBURSEMENT_REVERTED_TO_CREDIT_CARD"]
            break
        case "REPAY_CREDIT":
            transactionActionTypes = ["REPAY_CREDIT_WITH_FIAT", "REPAY_CREDIT_WITH_CRYPTO", "REPAY_CREDIT"]
            break    
        case "PAYMENT":
            transactionActionTypes = ["PAYMENT", "PAYMENT_WITH_CREDIT_CARD"]
            break
        case "PAYMENT_REFUND":
            transactionActionTypes = ["PAYMENT_REFUND"]
            break
        case "INTEREST":
            transactionActionTypes = ["DEPOSIT_CRYPTO_FROM_INTEREST", "LOAN_INTEREST"]
            break
        case "FEE":
            transactionActionTypes = ["MARGIN_FEE", "FEE"]
            break
        case "MARGIN_LOSS":
            transactionActionTypes = ["SEND_CRYPTO_FROM_MARGIN", "SEND_FIAT_FROM_MARGIN", "MARGIN_LOSS"]
            break
        case "MARGIN_GAIN":
            transactionActionTypes = ["RECEIVE_CRYPTO_FROM_MARGIN", "RECEIVE_FIAT_FROM_MARGIN", "MARGIN_GAIN"]
            break
        case "FUTURE_LOSS":
            transactionActionTypes = ["FUTURE_LOSS"]
            break
        case "FUTURE_PROFIT":
            transactionActionTypes = ["FUTURE_PROFIT"]
            break
        case "REALIZED_PROFIT":
            transactionActionTypes = ["REALIZED_PROFIT"]
            break
        case "REALIZED_LOSS":
            transactionActionTypes = ["REALIZED_LOSS"]
            break    
        case "BORROW":
            transactionActionTypes = ["BORROW", "BORROW_CRYPTO"]
            break
        case "COLLATERAL_ADDED":
            transactionActionTypes = ["COLLATERAL_ADDED"]
            break
        case "COLLATERAL_RETURNED":
            transactionActionTypes = ["COLLATERAL_RETURNED"]
            break    
        case "LEND":
            transactionActionTypes = ["LEND"]
            break
        case "UNLEND":
            transactionActionTypes = ["UNLEND"]
            break
        case "DONATION":
            transactionActionTypes = ["DONATION"]
            break
        case "LOST":
            transactionActionTypes = ["LOST"]
            break
        case "GIFT":
            transactionActionTypes = ["GIFT"]
            break
        case "REPAY_LOAN":
            transactionActionTypes = ["REPAY_LOAN"]
            break
        case "MINING":
            transactionActionTypes = ["MINING"]
            break
        case "NOT_TAXABLE_EVENT":
            transactionActionTypes = ["NOT_TAXABLE_EVENT"]
            break       
        default:
            transactionActionTypes = []
            break
    }
    return transactionActionTypes
}

export const getNewsFeedTypeFromString = (value) => {
    let string
    switch (value) {
        case "COINTELEGRAPH":
            string = "Cointelegraph"
            break
        case "COINDESK":
            string = "Coindesk"
            break
        case "DECRYPT":
            string = "Decrypt"
            break        
        default:
            string = value
            break
    }
    return string
}