<template>
  <v-dialog v-model="dialog" @click:outside="close" persistent max-width="800px">
    <v-card class="dialog-top-color">
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-toolbar-title>
          {{ $t('address.updateAddressTitle') }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon color="error">
            mdi-close-box
          </v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-row no-gutters class="mb-3">
          <v-col>
            {{ $t('address.updateAddressDescription') }}
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field v-model="selectedUserAddress.name" :label="$t('label.name')" solo :hint="$t('label.name')"
              persistent-hint>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-text-field v-model="selectedUserAddress.address.address" :label="$t('label.address')" disabled solo
              :hint="$t('label.address')" persistent-hint>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="isExchange(selectedUserAddress.address.address)" no-gutters>
          <v-col>
            <v-text-field v-model="selectedUserAddress.apiKey" :label="$t('label.apiKey')"
              :type="showKey ? 'text' : 'password'" :append-icon="showKey ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showKey = !showKey" solo :hint="$t('label.apiKey')" persistent-hint>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="isExchange(selectedUserAddress.address.address)" no-gutters>
          <v-col>
            <v-text-field v-model="selectedUserAddress.apiSecret" :label="$t('label.apiSecret')"
            :type="showSecret ? 'text' : 'password'" :append-icon="showSecret ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showSecret = !showSecret" solo :hint="$t('label.apiSecret')" persistent-hint>
          </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters
          v-if="isKucoin(selectedUserAddress.address.address) && isExchange(selectedUserAddress.address.address)">
          <v-col>
            <v-text-field v-model="selectedUserAddress.passphrase" :label="$t('label.passphrase')"
            :type="showPassphrase ? 'text' : 'password'" :append-icon="showPassphrase ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassphrase = !showPassphrase" solo :hint="$t('label.passphrase')" persistent-hint>
          </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="selectedUserAddress.userAddressType == 'PERSONAL'" no-gutters>
          <v-col>
            <add-tag-select>
          </add-tag-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="updateAddress" color="accent" class="white--text ma-3"
          :loading="loading" tile>{{ $t("address.updateAddressButton") }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xs" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AddTagSelect from '../select/AddTagSelect.vue'
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex';
export default {
  name: 'update-address-dialog',
  components: { AddTagSelect },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showKey: false,
      showSecret: false,
      showPassphrase: false,
      loading: false
    }
  },
  methods: {
    ...mapActions("selectedUserAddress", ["updateUserAddress"]),
    ...mapActions("alert", ["createAlert"]),
    close() {
      this.$emit('update:dialog', false)
    },
    isExchange(address) {
      return this.$SUPPORTED_EXCHANGE_TRANSACTIONS.some((exchange) => {
        if (address.toLowerCase().startsWith(exchange.toLowerCase())) {
          return true;
        }
      })
    },
    isKucoin(address) {
      if (address.toLowerCase().startsWith("kucoin")) {
        return true;
      }
      return false;
    },
    updateAddress() {
      this.loading = true;
      let payload = { ...this.selectedUserAddress }
      this.updateUserAddress(payload).then(() => {
        this.createAlert({ message: this.$t('message.updateUserAddressSuccess'), type: "success" })
        this.$emit("addressUpdated", true);
      }).catch((err) => {
        if (err.response.data && err.response.data.message) {
          if (err.response.data.message == 'ADDRESS_NOT_PRESENT') {
            this.createAlert({ message: this.$t('message.updateUserAddressAddressNotPresentError'), type: "error" })
          }
          if (err.response.data.message == 'ADDRESS_CANNOT_BE_WATCHLISTED') {
            this.createAlert({ message: this.$t('message.updateUserAddressAddressCannotBeWatchlistedError'), type: "error" })
          }
          if (err.response.data.message == 'INVALID_API_KEY') {
            this.createAlert({ message: this.$t('message.addPersonalExchangeAddressInvalidApiKeyError'), type: "error" })
          }
        } else {
          this.createAlert({ message: this.$t('message.genericError'), type: "error" })
        }
      }).finally(() => {
        this.loading = false
      });
    }
  },
  computed: {
    ...mapFields("selectedUserAddress", ["selectedUserAddress"])
  }
}
</script>

<style></style>