<template>
  <v-container fluid>
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-col>
        <address-router-select
          :addresses="totalAddresses"
          path="/overview"
        ></address-router-select>
      </v-col>
      <v-col cols="auto" class="pt-5">
        <router-button
          :text="$t('connection.addConnectionButton')"
          redirect="/add-connection"
        ></router-button>
      </v-col>
      <v-col :align="'right'">
        <h1>
          {{
            balances.map((x) => x.value).reduce((a, b) => a + b, 0)
              | CurrencyFormatter(loggedUser.settings.currency)
          }}
        </h1>
        <h4>{{ $t("label.totalBalance") }}</h4>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.xs" no-gutters>
      <v-col>
        <address-router-select
          :addresses="totalAddresses"
          path="/overview"
        ></address-router-select>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.xs" no-gutters class="my-3">
      <v-spacer />
      <v-col>
        <router-button
          :text="$t('connection.addConnectionButton')"
          redirect="/add-connection"
        ></router-button>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row v-if="$vuetify.breakpoint.xs">
      <v-col :align="'center'">
        <h4>{{ $t("label.totalBalance") }}</h4>
        <h1>
          {{
            balances.map((x) => x.value).reduce((a, b) => a + b, 0)
              | CurrencyFormatter(loggedUser.settings.currency)
          }}
        </h1>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndUp" >
      <v-col>
        <v-row v-if="$vuetify.breakpoint.mdAndUp" >
          <v-col class="pa-0">
            <overview-card
              :balances="getBlockchainTypeBalances"
              balanceType="BLOCKCHAIN"
              :maxLimit="8"
            ></overview-card>
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.sm" >
          <v-col class="pa-0">
                <overview-card
                  :balances="getBlockchainTypeBalances"
                  balanceType="BLOCKCHAIN"
                  :maxLimit="6"
                ></overview-card>

          </v-col>
        </v-row>
        <v-row>
          <v-col xl="4" lg="4" md="4" sm="6" xs="12">
            <blockchain-select
              @blockchainTypeSelected="updateBlockchainTypes"
            ></blockchain-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <overview-tabs
              :addresses="addresses"
              :blockchainTypes="blockchainTypes"
            ></overview-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template v-if="$vuetify.breakpoint.xs">
      <v-row>
        <v-col>
          <overview-card
            :balances="getBlockchainTypeBalances"
            balanceType="BLOCKCHAIN"
            :maxLimit="3"
          ></overview-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <blockchain-select
            @blockchainTypeSelected="updateBlockchainTypes"
          ></blockchain-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <overview-tabs
            :addresses="addresses"
            :blockchainTypes="blockchainTypes"
          ></overview-tabs>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
//import AddressAvatarComponent from '../../components/misc/AddressAvatarComponent.vue';
import OverviewCard from "../../components/card/OverviewCard.vue";
import OverviewTabs from "../../components/tabs/OverviewTabs.vue";
import BlockchainSelect from "../../components/select/BlockchainSelect.vue";
import RouterButton from "../../components/button/RouterButton.vue";
import AddressRouterSelect from "../../components/select/AddressRouterSelect.vue";
//import SyncAddressesButton from "../../components/button/SyncAddressesButton.vue";
export default {
  name: "address-overview-page",
  components: {
    BlockchainSelect,
    //AddressAvatarComponent,
    OverviewCard,
    OverviewTabs,
    RouterButton,
    AddressRouterSelect,
    //SyncAddressesButton,
  },
  data() {
    return {
      blockchainTypes: [],
      addresses: [],
    };
  },
  methods: {
    ...mapActions("selectedUserAddress", ["fetchUserAddress"]),
    ...mapActions("addressBalance", ["fetchAddressBalances"]),
    ...mapActions("alert", ["createAlert"]),
    fetchData(successfulSync) {
      if (successfulSync) {
        this.fetchUserAddress(this.$route.params.address).then(() => {
          console.log("address fetched");
        });
      }
    },
    updateBlockchainTypes(newBlockchainTypes) {
      if (!newBlockchainTypes) {
        return;
      }
      this.blockchainTypes = newBlockchainTypes;
    },
    getUpdated(address) {
      if (this.isUpdating(address)) {
        return this.$t("address.updating");
      } else {
        return (
          this.$t("address.lastSync") +
          " " +
          this.$options.filters.LastDateFormatter(address.updated)
        );
      }
    },
    isUpdating(address) {
      return address.updating || address.updated == null;
    },
  },
  watch: {
    // To make sure the data is fetched when the address is changed (lists are not reactive in Vue)
    selectedUserAddress: {
      handler(newValue, oldValue) {
        if (newValue && newValue != oldValue) {
          this.addresses.splice(0, 1, newValue.address.address);
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapFields("selectedUserAddress", ["selectedUserAddress"]),
    ...mapFields("addressBalance", ["balances"]),
    ...mapFields("userAddresses", { totalAddresses: "addresses" }),
    ...mapFields("loggedUser", ["loggedUser"]),
    getAddressTagColor() {
      if (this.selectedUserAddress.tag) {
        return this.selectedUserAddress.tag.color;
      }
      return null;
    },
    getAddressTagName() {
      if (this.selectedUserAddress.tag) {
        return this.selectedUserAddress.tag.name;
      }
      return null;
    },
    getBlockchainTypeBalances() {
      return Object.values(
        this.balances.reduce((agg, bal) => {
          if (agg[bal.blockchainType] === undefined)
            agg[bal.blockchainType] = {
              blockchainType: bal.blockchainType,
              value: 0,
            };
          agg[bal.blockchainType].value += +bal.value;
          return agg;
        }, {})
      );
    },
    getAssetTypeBalances() {
      return Object.values(
        this.balances.reduce((agg, bal) => {
          if (agg[bal.assetType] === undefined)
            agg[bal.assetType] = { assetType: bal.assetType, value: 0 };
          agg[bal.assetType].value += +bal.value;
          return agg;
        }, {})
      );
    },
  },
  mounted() {
    this.fetchUserAddress(this.$route.params.address)
      .then(() => {
        console.log("address fetched");
      })
      .catch((err) => {
        if (err.response.data && err.response.data.message) {
          if (err.response.data.message == "USER_ADDRESS_NOT_FOUND") {
            this.createAlert({
              message: this.$t("message.userAddressNotFoundError"),
              type: "error",
            });
          }
        } else {
          this.createAlert({
            message: this.$t("message.genericError"),
            type: "error",
          });
        }
        this.$router.push("/address-not-found");
      });
    this.fetchAddressBalances(this.$route.params.address)
      .then(() => {
        console.log("balances fetched");
      })
      .catch((err) => {
        if (err.response.data && err.response.data.message) {
          if (err.response.data.message == "USER_ADDRESS_NOT_FOUND") {
            this.createAlert({
              message: this.$t("message.userAddressNotFoundError"),
              type: "error",
            });
          }
        } else {
          this.createAlert({
            message: this.$t("message.genericError"),
            type: "error",
          });
        }
        this.$router.push("/address-not-found");
      });
  },
};
</script>

<style></style>