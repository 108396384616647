<template>
  <!-- TODO: try Treeview from Vuetify -->
  <v-list :align="'center'" :justify="'center'">
    <v-list-item
      link
      to="/overview"
      :color="$vuetify.theme.dark ? null : 'accent'"
    >
      <v-list-item-title>
        {{ $t("user.overviewPageButton") }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item
      link
      to="/connection-management"
      :color="$vuetify.theme.dark ? null : 'accent'"
    >
      <v-list-item-title>
        {{ $t("connection.connectionManagement") }}
      </v-list-item-title>
    </v-list-item>
    <v-list-item
      link
      to="/tax-center"
      :color="$vuetify.theme.dark ? null : 'accent'"
    >
      <v-list-item-title>
        {{ $t("tax.taxCenterPageButton") }}
      </v-list-item-title>
    </v-list-item>

    <v-list-item
      link
      to="/pricing-plans"
      :color="$vuetify.theme.dark ? null : 'accent'"
    >
      <v-list-item-title>
        {{ $t("plan.pricingPlans") }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
export default {
  components: {},
  name: "navigation-bar-component",
  data() {
    return {};
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapGetters("loggedUser", ["isFreeUser"]),
  },
  methods: {},
};
</script>
<style scoped>
.fixedBottom {
  position: fixed !important;
  bottom: 0 !important;
  width: 100%;
}
</style>
