import { render, staticRenderFns } from "./HistoryTab.vue?vue&type=template&id=6100da55&"
import script from "./HistoryTab.vue?vue&type=script&lang=js&"
export * from "./HistoryTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTabItem } from 'vuetify/lib/components/VTabs';
installComponents(component, {VIcon,VImg,VTabItem})
