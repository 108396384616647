<template>
  <v-btn :icon="isIconButton" @click="redirectToPage" color="accent" elevation="0" class="white--text pa-0" tile block>
    <template v-if="isIconButton">
      <v-icon color="primary">
        {{ icon }}
      </v-icon>
    </template>
    <template v-else>
      <template>
          <div class="button-corner">
              <div class="button-transparent-triangle"></div>
              <div class="button-colored-triangle"></div>
          </div>
          <span class="px-3">{{text}}</span>
      </template>
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'router-button',
  props: {
    text: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: null
    },
    redirect: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isIconButton: this.icon != null
    }
  },
  methods: {
    redirectToPage() {
      this.$router.push(this.redirect)
    }
  }


}
</script>

<style>
.button-transparent-triangle {
    border-left: 10px solid transparent;
    border-right: 10px solid var(--v-background-base);
    border-bottom: 10px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    right: 0px;
    z-index: 2;
}

.button-colored-triangle {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 0 10px;
    border-color: rgba(255, 255, 255, 0.5);
}

.button-corner {
    position: absolute;
    top: -10px;
    right: 0;
    width: 10px;
    height: 10px;
}
</style>