<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-row>
          <v-col
            :align="'start'"
            :justify="'start'"
            xl="2"
            lg="2"
            md="3"
            sm="4"
            xs="6"
            cols="12"
          >
            <v-card class="pa-2" height="100%">
              <v-row :align="'center'" :justify="'start'">
                <v-col cols="auto" class="pr-0">
                  <v-avatar size="25" class="ml-1">
                    <v-icon>mdi-wallet-outline</v-icon>
                  </v-avatar>
                </v-col>
                <v-col cols="auto">
                  <v-row no-gutters :align="'start'" justify="start">
                    <v-col cols="auto" :align="'start'" justify="start">
                      <template>
                        <b
                          ><small>
                            {{ $t("label.totalBalance") }}
                          </small></b
                        >
                      </template>
                    </v-col>
                  </v-row>
                  <v-row no-gutters :align="'start'" justify="start">
                    <v-col cols="auto" :align="'start'" justify="start">
                      {{
                        balances.map((x) => x.value).reduce((a, b) => a + b, 0)
                          | CurrencyFormatter(loggedUser.settings.currency)
                      }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            v-for="(balance, i) in getBalances"
            :key="i"
            :align="'start'"
            :justify="'start'"
            xl="2"
            lg="2"
            md="3"
            sm="4"
            xs="6"
            cols="12"
          >
            <v-card class="pa-2" height="100%">
              <v-row :align="'center'" :justify="'start'">
                <v-col cols="auto" class="pr-0">
                  <v-avatar size="25" class="ml-1">
                    <v-img
                      :src="
                        require(`../../assets/blockchain/${balance.blockchainType}.svg`)
                      "
                      contain
                    >
                    </v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="auto">
                  <v-row no-gutters :align="'start'" justify="start">
                    <v-col cols="auto" :align="'start'" justify="start">
                      <template v-if="balanceType == 'BLOCKCHAIN'">
                        <b
                          ><small>{{
                            balance.blockchainType | BlockchainTypeFormatter
                          }}</small></b
                        >
                      </template>
                    </v-col>
                  </v-row>
                  <v-row no-gutters :align="'start'" justify="start">
                    <v-col cols="auto" :align="'start'" justify="start">
                      {{
                        balance.value
                          | CurrencyFormatter(loggedUser.settings.currency)
                      }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col :align="'end'" :justify="'end'" cols="12" class="pt-0" v-if="balances.length > maxLimit">
            <template v-if="showMore">
              <span class="mx-0"
                ><small>{{ $t("label.showLess") }}</small></span
              >
              <v-btn icon @click="showMore = !showMore" class="">
                <v-icon> mdi-chevron-up </v-icon>
              </v-btn>
            </template>
            <template v-else>
              <span class="mx-0"
                ><small>{{ $t("label.showMore") }}</small></span
              >
              <v-btn icon @click="showMore = !showMore" class="">
                <v-icon> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" v-if="!$vuetify.breakpoint.xs">
        <v-row>
          <v-col :align="'end'" :justify="'end'" cols="auto">
            <router-button
              :text="$t('connection.addConnectionButton')"
              redirect="/add-connection"
            ></router-button>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.xs">
      <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
      <v-col cols="auto">
        <router-button
          :text="$t('connection.addConnectionButton')"
          redirect="/add-connection"
        ></router-button>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import RouterButton from "../button/RouterButton.vue";
export default {
  name: "overview-card",
  props: {
    balances: {
      type: Array,
      required: true,
    },
    balanceType: {
      type: String,
      required: true,
    },
    limit: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxLimit: {
      type: Number,
      required: false,
      default: 8,
    },
  },
  components: {
    RouterButton,
  },
  data() {
    return {
      showMore: false,
    };
  },
  computed: {
    ...mapFields("userAddresses", ["addresses"]),
    ...mapFields("loggedUser", ["loggedUser"]),
    getBalancesTotalValue() {
      return this.balances
        .map((balance) => balance.value)
        .reduce((a, b) => a + b);
    },
    getBalances() {
      let sortedBalances = [];
      if (this.balanceType == "ASSET") {
        sortedBalances = [...this.balances].sort(
          (a, b) => a.assetType - b.assetType
        );
      } else {
        sortedBalances = [...this.balances].sort((a, b) => b.value - a.value);
      }
      if (this.showMore || !this.limit) {
        return sortedBalances;
      } else {
        return sortedBalances.slice(0, this.maxLimit);
      }
    },
  },
  methods: {
    getUserAddressFromAddress(address) {
      return this.addresses.filter(
        (userAddress) => userAddress.address.address === address
      )[0];
    },
    getAddressTagColor(address) {
      if (address.tag) {
        return address.tag.color;
      }
      return null;
    },
    getAddressTagName(address) {
      if (address.tag) {
        return address.tag.name;
      }
      return null;
    },
  },
};
</script>

<style></style>