<template>
  <v-container fluid>
    <template v-if="loggedUser.externalTier == null">
      <v-row
        no-gutters
        :align="'center'"
        :justify="'center'"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <v-spacer />
        <v-col cols="auto">
          <h1 class="mr-3 pb-8">{{ $t("plan.pricingPlansTitle") }}</h1>
        </v-col>
        <v-col cols="auto">
          <v-select
            :items="availableTaxYears"
            outlined
            item-color="accent"
            v-model="taxYear"
            :style="{ width: '140px' }"
          >
            <template v-slot:selection="{ item }">
              <h1 class="accent--text">{{ item }}</h1>
            </template>
          </v-select>
        </v-col>
        <v-spacer />
      </v-row>
      <template v-if="$vuetify.breakpoint.xs">
        <v-row no-gutters :align="'center'" :justify="'center'">
          <v-col :align="'center'" :justify="'center'">
            <h1>{{ $t("plan.pricingPlansTitle") }}</h1>
          </v-col>
        </v-row>
        <v-row no-gutters :align="'center'" :justify="'center'">
          <v-select
            :items="availableTaxYears"
            outlined
            item-color="accent"
            v-model="taxYear"
            class="pt-8"
            :style="{ width: '140px' }"
          >
            <template v-slot:selection="{ item }">
              <h1 class="accent--text">{{ item }}</h1>
            </template>
          </v-select>
        </v-row>
      </template>
      <v-row :align="'center'" :justify="'center'" no-gutters>
        <v-col :align="'center'" :justify="'center'">
          <p
            v-html="
              $t('plan.pricingPlansDescription', {
                taxYear: taxYear,
                transactions: taxYearTransactions,
              })
            "
          ></p>
        </v-col>
      </v-row>
      <v-row
        v-if="getPricingPlanType == 'YOUNG_PLATFORM'"
        type="info"
        no-gutters
      >
        <v-col>
          <v-alert color="#00D672" outlined dense>
            <template v-slot:prepend>
              <v-icon color="#00D672" class="mr-3"
                >mdi-information-outline</v-icon
              >
            </template>
            {{
              $t("plan.youngPlatformPlan", {
                taxYear: taxYear,
                tier: $options.filters.ExternalTierFormatter(
                  loggedUser.externalTier
                ),
              })
            }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row v-if="loggedUser.userRole !== 'USER'" type="info" no-gutters>
        <v-col>
          <v-alert type="info" outlined dense>
            <template v-slot:prepend>
              <v-icon color="accent" class="mr-3"
                >mdi-information-outline</v-icon
              >
            </template>
            {{ $t("message.superUser") }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row justify="center" no-gutters>
        <v-col cols="12" md="3" sm="6" class="d-flex">
          <pricing-card
            :pricingPlanType="'LITE'"
            :taxYear="taxYear"
            class="w-100"
          ></pricing-card>
        </v-col>
        <v-col cols="12" md="3" sm="6" class="d-flex">
          <pricing-card
            :pricingPlanType="'BASE'"
            :taxYear="taxYear"
            class="w-100"
          ></pricing-card>
        </v-col>
        <v-col cols="12" md="3" sm="6" class="d-flex">
          <pricing-card
            :pricingPlanType="'STANDARD'"
            :taxYear="taxYear"
            class="w-100"
          ></pricing-card>
        </v-col>
        <v-col cols="12" md="3" sm="6" class="d-flex">
          <pricing-card
            :pricingPlanType="'PREMIUM'"
            :taxYear="taxYear"
            class="w-100"
          ></pricing-card>
        </v-col>
      </v-row>
    </template>
    <template v-if="loggedUser.externalTier != null">
      <v-row :align="'center'" :justify="'center'">
        <v-col :align="'center'" :justify="'center'">
          <p
            v-html="
              $t('plan.pricingPlansDescriptionYoung', {
                tier: $options.filters.ExternalTierFormatter(
                  loggedUser.externalTier
                ),
              })
            "
          ></p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" xl="3" md="4" sm="6" class="d-flex">
          <young-pricing-card
            :pricingPlanType="'YOUNG_PLATFORM'"
            :tier="loggedUser.externalTier"
            class="w-100"
          ></young-pricing-card>
        </v-col>
      </v-row>
    </template>
    <v-row>
      <v-col cols="12">
        <v-alert
          type="info"
          outlined
          dense
          v-if="getPricingPlanType != 'ENTERPRISE'"
        >
          <template v-slot:prepend>
            <v-icon color="accent" class="mr-3">mdi-information-outline</v-icon>
          </template>
          <v-row :align="'center'">
            <v-col>
              <b>{{ $t("plan.customSolutionTitle") }}</b>
              {{ $t("plan.customSolutionDescription") }}
            </v-col>
            <v-col cols="auto">
              <v-btn @click="sendEmail" icon>
                <v-icon color="accent">mdi-email-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
        <v-alert type="info" outlined dense v-else>
          <template v-slot:prepend>
            <v-icon color="accent" class="mr-3">mdi-information-outline</v-icon>
          </template>
          {{
            $t("plan.customPlanActivated", {
              transactions: loggedUser.pricingPlan.transactionLimit,
              taxYear: new Date().getFullYear() - 1,
            })
          }}</v-alert
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-alert color="#635BFF" outlined dense>
          <template v-slot:prepend>
            <v-img
              :src="require(`../../assets/plan/STRIPE.svg`)"
              max-width="75"
              class="mr-3"
            ></v-img>
          </template>
          {{ $t("plan.stripeDescription") }}</v-alert
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PricingCard from "../../components/card/PricingCard.vue";
import YoungPricingCard from "../../components/card/YoungPricingCard.vue";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
export default {
  name: "pricing-plans-page",
  components: {
    PricingCard,
    YoungPricingCard,
  },
  data() {
    return {
      loading: false,
      menu: false,
      picker: null,
      currentYear: new Date().getFullYear(),
      taxYear: new Date().getFullYear() - 1,
    };
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapFields("tax", ["availableTaxYears", "taxYearTransactions"]),
    getSubject() {
      return this.$t("message.contactUsSubject");
    },
    getPricingPlanType() {
      let plan = this.loggedUser.pricingPlan.taxYearsAvailable.find(
        (x) => x.taxYear == this.taxYear
      );
      if (plan != null) {
        return plan.pricingPlanType;
      } else {
        return "FREE";
      }
    },
  },
  methods: {
    ...mapActions("tax", [
      "fetchAvailableTaxYears",
      "fetchTaxYearTransactions",
    ]),
    saveYear(year) {
      this.taxYear = year;
      this.menu = false;
    },
    sendEmail() {
      var mail = `mailto:support@okipo.io?subject=${this.getSubject}`;
      var a = document.createElement("a");
      a.href = mail;
      a.click();
    },
  },
  watch: {
    menu(val) {
      val && this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    taxYear(val, oldVal) {
      if (val !== oldVal) {
        this.fetchTaxYearTransactions(this.taxYear);
      }
    },
  },
  created() {
    if (this.$route.params.taxYear) {
      this.taxYear = this.$route.params.taxYear;
    }
    this.fetchAvailableTaxYears();
    this.fetchTaxYearTransactions(this.taxYear);
  },
};
</script>

<style scoped>
@media (max-width: 959.99px) {
  .d-flex {
    display: flex !important;
    justify-content: center;
  }
}
.info-top-color {
  border-top: solid 5px var(--v-accent-base) !important;
}
</style>