<template>
  <v-container fluid>
    <v-row :align="'center'" :justify="'center'" class="mb-5">
      <v-spacer v-if="!$vuetify.breakpoint.xs" />
      <v-col :align="'center'" :justify="'center'">
        <h1>{{ $t("tax.createTaxReportTitle") }}</h1>
      </v-col>
      <v-spacer v-if="!$vuetify.breakpoint.xs" />
    </v-row>
    <v-stepper v-model="e1" tile >
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" color="accent">
          {{ $t("tax.createTaxReportStep1Title") }}
        </v-stepper-step>
        <v-divider></v-divider>
        <!--
                <v-stepper-step :complete="e1 > 2" step="2" color="accent">
                    {{ $t('tax.createTaxReportStep2Title') }}
                </v-stepper-step>

                <v-divider></v-divider>
                -->
        <v-stepper-step :complete="e1 > 2" step="2" color="accent">
          {{ $t("tax.createTaxReportStep3Title") }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step color="accent" step="3">
          {{ $t("tax.createTaxReportStep4Title") }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-row>
            <v-col>
              <v-row class="mb-3" no-gutters>
                <v-col>
                  <p v-html="$t('tax.createTaxReportAddressDescription')" />
                </v-col>
              </v-row>
              <v-row class="mb-3">
                <v-col
                  v-for="(address, i) in getPersonalAddresses"
                  :key="i"
                  :align="'center'"
                  xl="2"
                  lg="3"
                  md="3"
                  sm="4"
                  xs="6"
                  cols="12"
                >
                  <v-card class="pa-3" height="100%">
                    <v-row :align="'center'" :justify="'start'">
                      <v-col cols="auto">
                        <v-avatar size="25">
                          <v-img
                            :src="
                              require(`../../assets/blockchain/${getBlockchainTypeFromAddress(
                                address.address.address
                              )}.svg`)
                            "
                            contain
                          >
                          </v-img>
                        </v-avatar>
                      </v-col>
                      <v-col cols="auto" :align="'start'" justify="start">
                        <h3>
                          {{ address.name }}
                        </h3>
                      </v-col>
                    </v-row>
                    <v-row
                      class="pt-1"
                      no-gutters
                      :align="'start'"
                      justify="start"
                    >
                      <v-col cols="auto" :align="'start'" justify="start">
                        <small
                          >({{ $t("label.updated") }}
                          <i>{{
                            address.address.updated | LastDateFormatter
                          }}</i
                          >)</small
                        >
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <template v-if="$vuetify.breakpoint.xs">

            <v-row class="my-5" no-gutters>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn text tile @click="$router.push('/tax-center')">{{
                  $t("label.back")
                }}</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="accent" tile @click="e1 = 2">{{
                  $t("label.continue")
                }}</v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-row no-gutters class="mt-3">
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-btn text tile @click="$router.push('/tax-center')">{{
                  $t("label.back")
                }}</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn color="accent" tile @click="e1 = 2">{{
                  $t("label.continue")
                }}</v-btn>
              </v-col>
            </v-row>
          </template>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-row class="mt-3 mb-3" no-gutters>
            <v-col>
              {{ $t("tax.downloadTaxReportDialogDescription") }}
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col>
              <country-select
                :selectedCountry="country"
                :countries="defaultCountries"
                @updateCountry="updateCountry"
              >
              </country-select>
            </v-col>
            <v-col>
              <currency-select
                :selectedCurrency="taxCurrency"
                :currencies="defaultTaxCurrencies"
                @updateCurrency="updateTaxCurrency"
                :disabled="isChoiceDisabled"
              >
              </currency-select>
            </v-col>
            <v-col>
              <v-select
                v-model="costBasisMethod"
                :items="defaultCostBasisMethods"
                persistent-hint
                :hint="$t('tax.costBasisMethod')"
                :label="$t('tax.costBasisMethod')"
                :disabled="isChoiceDisabled"
                solo
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col cols="auto" class="mr-3">
              <v-switch
                v-model="emoney"
                inset
                :label="$t('tax.emoney')"
                color="accent"
              >
                <template slot="append">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                    </template>
                    <span>
                      {{ $t("tax.emoneySwitchTooltip") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
            </v-col>
            <v-col cols="auto">
              <v-switch
                v-model="threshold"
                inset
                :label="$t('tax.threshold')"
                color="accent"
              >
                <!-- Show tooltip when mouseover on appen icon -->
                <template slot="append">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                    </template>
                    <span>
                      {{ $t("tax.thresholdSwitchTooltip") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-switch>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-3">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn text tile @click="e1 = 1">{{ $t("label.back") }}</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                tile
                color="accent"
                :disabled="isButtonDisabled"
                @click="goToRecap"
                >{{ $t("label.continue") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-stepper-content>
        <!--
                <v-stepper-content step="2">
                    <v-row class="mb-10">
                        <tax-center-history-table></tax-center-history-table>
                    </v-row>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="auto">
                            <v-btn text @click="e1 = 1">{{ $t('label.back') }}</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="accent" @click="e1 = 3">{{ $t('label.continue') }}</v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
                -->
        <v-stepper-content step="3">
          <v-row class="mt-3" no-gutters>
            <p v-html="$t('tax.createTaxReportLastStepDescription')"></p>

          </v-row>
          <v-row class="mt-5" no-gutters>
            <h3>{{ $t("tax.createTaxReportConnections") }}</h3>
          </v-row>
          <v-row>
            <v-col
              v-for="(address, i) in getPersonalAddresses"
              :key="i"
              :align="'center'"
              xl="2"
              lg="3"
              md="3"
              sm="4"
              xs="6"
              cols="12"
            >
              <v-card class="pa-3" height="100%">
                <v-row :align="'center'" :justify="'start'">
                  <v-col cols="auto">
                    <v-avatar size="25">
                      <v-img
                        :src="
                          require(`../../assets/blockchain/${getBlockchainTypeFromAddress(
                            address.address.address
                          )}.svg`)
                        "
                        contain
                      >
                      </v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="auto" :align="'start'" justify="start">
                    <h3>
                      {{ address.name }}
                    </h3>
                  </v-col>
                </v-row>
                <v-row class="pt-1" no-gutters :align="'start'" justify="start">
                  <v-col cols="auto" :align="'start'" justify="start">
                    <small
                      >({{ $t("label.updated") }}
                      <i>{{ address.address.updated | LastDateFormatter }}</i
                      >)</small
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-5" no-gutters>
            <h3>{{ $t("tax.createTaxReportSettings") }}</h3>
          </v-row>
          <v-row>
            <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
              <v-text-field
                outlined
                readonly
                :value="country | CountryTypeFormatter"
                :label="$t('label.country')"
              >
                <template v-slot:prepend-inner>
                  <v-avatar size="20">
                    <v-img
                      :src="require(`../../assets/country/${country}.svg`)"
                    ></v-img>
                  </v-avatar>
                </template>
              </v-text-field>
            </v-col>
            <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
              <v-text-field
                outlined
                readonly
                :value="taxCurrency"
                :label="$t('label.currency')"
              >
                <template v-slot:prepend-inner>
                  <v-avatar size="20">
                    <v-img
                      :src="require(`../../assets/currency/${taxCurrency}.svg`)"
                    ></v-img>
                  </v-avatar>
                </template>
              </v-text-field>
            </v-col>
            <v-col xl="2" lg="3" md="3" sm="6" xs="12" cols="12">
              <v-text-field
                outlined
                readonly
                :value="
                  $options.filters.CostBasisMethodFormatter(costBasisMethod)
                "
                :label="$t('label.costBasisMethod')"
              >
              </v-text-field>
            </v-col>
            <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
              <v-text-field
                outlined
                readonly
                :value="$options.filters.YesNoFormatter(emoney)"
                :label="$t('tax.emoney')"
              >
                <template v-slot:prepend-inner>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                    </template>
                    <span>
                      {{ $t("tax.emoneyTooltip") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col xl="2" lg="3" md="4" sm="6" xs="12" cols="12">
              <v-text-field
                outlined
                readonly
                :value="$options.filters.YesNoFormatter(threshold)"
                :label="$t('tax.threshold')"
              >
                <template v-slot:prepend-inner>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                    </template>
                    <span>
                      {{ $t("tax.thresholdTooltip") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-3">
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <v-btn text tile @click="e1 = 2">{{ $t("label.back") }}</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="accent" tile @click="createTaxReportRequest" >
                {{
                $t("tax.createTaxReportButton")
              }}</v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <!--
    <v-row class="mt-5" :align="'end'" justify="end" no-gutters>
        <v-spacer v-if="$vuetify.breakpoint.xs" />
        <v-col cols="auto" :align="'end'" justify="end">
                <router-button
                  :text="$t('connection.addConnectionButton')"
                  redirect="/add-connection"
                ></router-button>
              </v-col>
              <v-spacer v-if="$vuetify.breakpoint.xs" />
    </v-row>
    -->
  </v-container>
</template>

<script>
// import TaxCenterHistoryTable from '../../components/table/TaxCenterHistoryTable.vue'
import CountrySelect from "../../components/select/CountrySelect.vue";
import CurrencySelect from "../../components/select/CurrencySelect.vue";
//import BlockchainTypeComponent from "../../components/misc/BlockchainTypeComponent.vue";
import { checkAddressType } from "../../utils/utils";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
//import RouterButton from "../../components/button/RouterButton.vue";
export default {
  name: "user-create-tax-report-page",
  components: {
    CurrencySelect,
    CountrySelect,
    // TaxCenterHistoryTable,
    // BlockchainTypeComponent,
    //RouterButton,
  },
  data() {
    return {
      e1: 1,
      loading: false,
      taxCurrency: null,
      defaultTaxCurrencies: ["USD", "EUR"],
      defaultCountries: [
        { value: "ITALY", text: "ITALY", disabled: false },
        {
          value: "REST_OF_THE_WORLD",
          text: "REST_OF_THE_WORLD",
          disabled: false,
        },
      ],
      defaultCostBasisMethods: [
        {
          text: "LIFO",
          value: "LIFO",
        },
        {
          text: "FIFO",
          value: "FIFO",
        },
        {
          text: this.$t("tax.costBasisMethodAverageCost"),
          value: "AVERAGE_COST",
        },
      ],
    };
  },
  computed: {
    ...mapFields("tax", [
      "currency",
      "country",
      "costBasisMethod",
      "emoney",
      "threshold",
    ]),
    ...mapFields("userAddresses", ["addresses"]),
    ...mapFields("loggedUser", ["loggedUser"]),
    isChoiceDisabled() {
      if (this.country === "ITALY") {
        return true;
      }
      return false;
    },
    isButtonDisabled() {
      return (
        this.country == null ||
        this.taxCurrency == null ||
        this.costBasisMethod == null ||
        this.emoney == null ||
        this.threshold == null
      );
    },
    getPersonalAddresses() {
      return this.addresses
        .filter((x) => x.userAddressType === "PERSONAL")
        .sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    ...mapActions("alert", ["createAlert"]),
    ...mapActions("tax", ["requestTaxReport"]),
    ...mapActions("loggedUser", ["updateUserData"]),
    updateTaxCurrency(taxCurrency) {
      this.taxCurrency = taxCurrency;
    },
    updateCountry(country) {
      this.country = country;
      if (this.country === "ITALY") {
        this.taxCurrency = "EUR";
        this.costBasisMethod = "LIFO";
      }
    },
    updateUserSettings() {
      this.updateUserData(this.loggedUser);
    },
    getBlockchainTypeFromAddress(address) {
      let addressType = checkAddressType(address)
      if (addressType != null){
        return addressType
      }
      return address.split("_")[0].toUpperCase();
    },
    goToRecap() {
      this.e1 = 3;
      if (
        this.country != this.loggedUser.settings.country ||
        this.taxCurrency != this.loggedUser.settings.taxCurrency ||
        this.costBasisMethod != this.loggedUser.settings.costBasisMethod ||
        this.emoney != this.loggedUser.settings.emoney ||
        this.threshold != this.loggedUser.settings.threshold
      ) {
        this.loggedUser.settings.country = this.country;
        this.loggedUser.settings.taxCurrency = this.taxCurrency;
        this.loggedUser.settings.costBasisMethod = this.costBasisMethod;
        this.loggedUser.settings.emoney = this.emoney;
        this.loggedUser.settings.threshold = this.threshold;
        this.updateUserData(this.loggedUser);
      }
    },
    createTaxReportRequest() {
      this.loading = true;
      const payload = {
        currencyType: this.taxCurrency,
        countryType: this.country,
        costBasisMethodType: this.costBasisMethod,
        emoney: this.emoney == null ? false : this.emoney,
        threshold: this.threshold == null ? false : this.threshold,
      };
      this.requestTaxReport(payload)
        .then(() => {
          this.createAlert({
            message: this.$t("message.createTaxReportSuccess"),
            type: "success",
          });
          this.$router.push("/tax-center");
          this.$emit("taxReportCreated", true);
        })
        .catch((err) => {
          if (err.response.data && err.response.data.message) {
            if (err.response.data.message == "TAX_REPORT_ALREADY_EXISTS") {
              this.createAlert({
                message: this.$t("message.taxReportAlreadyExistsError"),
                type: "error",
              });
            } else if (err.response.data.message == "TAX_REPORT_IS_CREATING") {
              this.createAlert({
                message: this.$t("message.taxReportIsCreatingError"),
                type: "error",
              });
            } else if (err.response.data.message == "WRONG_PRICING_PLAN") {
              this.createAlert({
                message: this.$t("message.taxReportWrongPricingError"),
                type: "error",
              });
            } else {
              this.createAlert({
                message: this.$t("message.genericError"),
                type: "error",
              });
            }
          } else {
            this.createAlert({
              message: this.$t("message.genericError"),
              type: "error",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.country == null) {
      this.country = this.loggedUser.settings.country;
    }
    if (this.taxCurrency == null) {
      this.taxCurrency = this.loggedUser.settings.taxCurrency;
    }
    if (this.costBasisMethod == null) {
      this.costBasisMethod = this.loggedUser.settings.costBasisMethod;
    }
    if (this.emoney == null) {
      this.emoney =
        this.loggedUser.settings.emoney == null
          ? false
          : this.loggedUser.settings.emoney;
    }
    if (this.threshold == null) {
      this.threshold =
        this.loggedUser.settings.threshold == null
          ? false
          : this.loggedUser.settings.emoney;
    }
  },
};
</script>

<style scoped>
.button-transparent-triangle {
    border-left: 10px solid transparent;
    border-right: 10px solid var(--v-background-base);
    border-bottom: 10px solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    right: 0px;
    z-index: 2;
}

.button-colored-triangle {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 0 10px;
    border-color: rgba(255, 255, 255, 0.5);
}

.button-corner {
    position: absolute;
    top: -10px;
    right: 0;
    width: 10px;
    height: 10px;
}
</style>