import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import VueMoment from 'vue-moment'
import moment from 'moment'

import '@/components/global/importGlobalComponents.js'
import VueKeyCloak from '@caassis/vue-keycloak-ts'
import '@/utils/filters'
import { utilsMixin } from "@/utils/utilsMixin";
import VueApexCharts from "vue-apexcharts";
import 'vue-calendar-heatmap/dist/vue-calendar-heatmap.css'
import VueCalendarHeatmap from 'vue-calendar-heatmap/dist/vue-calendar-heatmap.common'
import VueCookies from 'vue-cookies'

Vue.use(VueCalendarHeatmap)
Vue.use(VueApexCharts);
Vue.use(VueCookies, { expire: '7d'})
Vue.component('apexchart', VueApexCharts)

// https://kazupon.github.io/vue-i18n/guide/datetime.html
const dateTimeFormats = {
  it: require('./languages/dates/it.json'),
  en: require('./languages/dates/en.json')
}

const messages = {
  en: require('./languages/messages/en.json'),
  it: require('./languages/messages/it.json')
};

// https://kazupon.github.io/vue-i18n/guide/number.html
const numberFormats = {
  en: require('./languages/numbers/en.json'),
  it: require('./languages/numbers/it.json')
}

Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'en',
  messages,
  dateTimeFormats,
  numberFormats
});

// BLOCKCHAIN_TYPE: add here all supported blockchain types
Vue.prototype.$SUPPORTED_FEEDS = ["COINTELEGRAPH", "COINDESK", "DECRYPT"]
Vue.prototype.$SUPPORTED_WALLETS = ["METAMASK", "TREZOR", "TREZOR_BITCOIN", "LEDGER", "LEDGER_BITCOIN", "TRUSTWALLET", "PHANTOM", "EXODUS", "BITBOX", "SAFEPAL", "BITGET_WALLET", "COINBASE_WALLET", "CRYPTOCOM_WALLET"]
Vue.prototype.$SUPPORTED_BLOCKCHAINS = ["BITCOIN", "ETHEREUM", "SOLANA", "POLYGON", "BSC", "AVALANCHE", "ARBITRUM", "FANTOM", "AURORA", "HECO", "MOONRIVER", "MOONBEAM", "CRONOS", "OPTIMISM", "XDAI", "CELO", "HARMONY", "BOBA", "CLV", "BASE", "BLAST", "POLYGONZK", "ARBITRUM_NOVA", "ZKSYNC"]
Vue.prototype.$SUPPORTED_PLATFORMS = ["REVOLUT", "RAYN"]
Vue.prototype.$SUPPORTED_EXCHANGES = ["BINANCE", "COINBASE", "KUCOIN", "BITPANDA", "KRAKEN", "BITSTAMP", "BITTREX", "HUOBI", "BITFINEX", "DIGIFINEX", "OKX", "GATEIO", "UPBIT", "BITHUMB", "CEXIO", "CRYPTOCOM", "CRYPTOCOMEXCHANGE", "YOUNGPLATFORM", "GEMINI", "POLONIEX", "NEXO", "BYBIT", "BIBOX", "CRYPTOSMART", "GENERIC", "REVOLUT", "UPHOLD", "LEDGERCSV","KRIPTOMAT", "RAYN", "CONIO", "BITGET", "ONETRADING", "SORARE", "ALGORAND", "COSMOS", "TERRA", "TERRACLASSIC"]
Vue.prototype.$SUPPORTED_EXCHANGE_TRANSACTIONS = []
Vue.prototype.$SUPPORTED_EXCHANGE_CSV = ["BINANCE", "CRYPTOCOM", "CRYPTOCOMEXCHANGE", "NEXO", "COINBASE", "BITPANDA", "YOUNGPLATFORM", "BITSTAMP", "BITTREX", "KRAKEN", "KUCOIN", "REVOLUT", "BITFINEX", "CRYPTOSMART", "BYBIT", "UPHOLD", "LEDGERCSV","KRIPTOMAT", "RAYN", "CONIO", "BITGET", "CEXIO", "ONETRADING", "SORARE", "ALGORAND", "COSMOS", "TERRA", "TERRACLASSIC"]
Vue.prototype.$SUPPORTED_EXCHANGE_CSV_AUTOMATIC = []
Vue.prototype.$COLORS = ["#004CA3", "#8A51A5", "#CB5E99", "#F47B89", "#FFA47E", "#FFD286", "#FFFFA6"]
Vue.prototype.$FREE_WATCHLIST_ADDRESSES = 3
Vue.prototype.$LEEWAY_DAYS=3
Vue.prototype.$BLUE_PALETTE = ["#0A244B", "#114084", "#3466AA", "#5B8FC3", "#82B7DC", "#A9CEE7"]
Vue.prototype.$EMONEY_TOKEN = [{name: "Euro Tether", symbol: "EURT"}, {name: "Tether", symbol: "USDT"}, {name: "USD Coin", symbol: "USDC"}, 
{name: "TrueUSD", symbol: "TUSD"}, {name: "Binance USD", symbol: "BUSD"}, {name: "Gemini Dollar", symbol: "GUSD"},
{name: "First Digital USD", symbol: "FDUSD"}, {name: "PayPal USD", symbol: "PYUSD"}, {name: "Pax Dollar", symbol: "USDP"}, {name: "Stasis Euro", symbol: "EURS"}]


Vue.use(VueMoment, {
  moment
});
Vue.config.productionTip = false
Vue.mixin(utilsMixin);
if (process.env.VUE_APP_KEYCLOAK_ENABLED === "true") {
  Vue.use(VueKeyCloak,
    {
      init: {
        onLoad: 'check-sso',
        checkLoginIframe: false
      },
      logout: {
        redirectUri: process.env.VUE_APP_REDIRECT
      },
      config: {
        realm: process.env.VUE_APP_KEYCLOAK_REALM,
        url: process.env.VUE_APP_KEYCLOAK_URL,
        clientId: process.env.VUE_APP_CLIENT_ID,
      },
      onReady: () => {
        new Vue({
          i18n,
          router,
          store,
          vuetify,
          render: h => h(App)
        }).$mount('#app')
      }
    })
} else {
  new Vue({
    i18n,
    router,
    store,
    vuetify,
    created() {
      console.log("Keycloak disabled")
    },
    render: h => h(App)
  }).$mount('#app')
}
