var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loggedUser.externalTier == null)?[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',{attrs:{"no-gutters":"","align":'center',"justify":'center'}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('h1',{staticClass:"mr-3 pb-8"},[_vm._v(_vm._s(_vm.$t("plan.pricingPlansTitle")))])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-select',{style:({ width: '140px' }),attrs:{"items":_vm.availableTaxYears,"outlined":"","item-color":"accent"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('h1',{staticClass:"accent--text"},[_vm._v(_vm._s(item))])]}}],null,false,3484709841),model:{value:(_vm.taxYear),callback:function ($$v) {_vm.taxYear=$$v},expression:"taxYear"}})],1),_c('v-spacer')],1):_vm._e(),(_vm.$vuetify.breakpoint.xs)?[_c('v-row',{attrs:{"no-gutters":"","align":'center',"justify":'center'}},[_c('v-col',{attrs:{"align":'center',"justify":'center'}},[_c('h1',[_vm._v(_vm._s(_vm.$t("plan.pricingPlansTitle")))])])],1),_c('v-row',{attrs:{"no-gutters":"","align":'center',"justify":'center'}},[_c('v-select',{staticClass:"pt-8",style:({ width: '140px' }),attrs:{"items":_vm.availableTaxYears,"outlined":"","item-color":"accent"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('h1',{staticClass:"accent--text"},[_vm._v(_vm._s(item))])]}}],null,false,3484709841),model:{value:(_vm.taxYear),callback:function ($$v) {_vm.taxYear=$$v},expression:"taxYear"}})],1)]:_vm._e(),_c('v-row',{attrs:{"align":'center',"justify":'center',"no-gutters":""}},[_c('v-col',{attrs:{"align":'center',"justify":'center'}},[_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.$t('plan.pricingPlansDescription', {
              taxYear: _vm.taxYear,
              transactions: _vm.taxYearTransactions,
            })
          )}})])],1),(_vm.getPricingPlanType == 'YOUNG_PLATFORM')?_c('v-row',{attrs:{"type":"info","no-gutters":""}},[_c('v-col',[_c('v-alert',{attrs:{"color":"#00D672","outlined":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":"#00D672"}},[_vm._v("mdi-information-outline")])]},proxy:true}],null,false,1020183594)},[_vm._v(" "+_vm._s(_vm.$t("plan.youngPlatformPlan", { taxYear: _vm.taxYear, tier: _vm.$options.filters.ExternalTierFormatter( _vm.loggedUser.externalTier ), }))+" ")])],1)],1):_vm._e(),(_vm.loggedUser.userRole !== 'USER')?_c('v-row',{attrs:{"type":"info","no-gutters":""}},[_c('v-col',[_c('v-alert',{attrs:{"type":"info","outlined":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":"accent"}},[_vm._v("mdi-information-outline")])]},proxy:true}],null,false,2067686496)},[_vm._v(" "+_vm._s(_vm.$t("message.superUser"))+" ")])],1)],1):_vm._e(),_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"3","sm":"6"}},[_c('pricing-card',{staticClass:"w-100",attrs:{"pricingPlanType":'LITE',"taxYear":_vm.taxYear}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"3","sm":"6"}},[_c('pricing-card',{staticClass:"w-100",attrs:{"pricingPlanType":'BASE',"taxYear":_vm.taxYear}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"3","sm":"6"}},[_c('pricing-card',{staticClass:"w-100",attrs:{"pricingPlanType":'STANDARD',"taxYear":_vm.taxYear}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"3","sm":"6"}},[_c('pricing-card',{staticClass:"w-100",attrs:{"pricingPlanType":'PREMIUM',"taxYear":_vm.taxYear}})],1)],1)]:_vm._e(),(_vm.loggedUser.externalTier != null)?[_c('v-row',{attrs:{"align":'center',"justify":'center'}},[_c('v-col',{attrs:{"align":'center',"justify":'center'}},[_c('p',{domProps:{"innerHTML":_vm._s(
            _vm.$t('plan.pricingPlansDescriptionYoung', {
              tier: _vm.$options.filters.ExternalTierFormatter(
                _vm.loggedUser.externalTier
              ),
            })
          )}})])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","xl":"3","md":"4","sm":"6"}},[_c('young-pricing-card',{staticClass:"w-100",attrs:{"pricingPlanType":'YOUNG_PLATFORM',"tier":_vm.loggedUser.externalTier}})],1)],1)]:_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.getPricingPlanType != 'ENTERPRISE')?_c('v-alert',{attrs:{"type":"info","outlined":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":"accent"}},[_vm._v("mdi-information-outline")])]},proxy:true}],null,false,2067686496)},[_c('v-row',{attrs:{"align":'center'}},[_c('v-col',[_c('b',[_vm._v(_vm._s(_vm.$t("plan.customSolutionTitle")))]),_vm._v(" "+_vm._s(_vm.$t("plan.customSolutionDescription"))+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.sendEmail}},[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-email-outline")])],1)],1)],1)],1):_c('v-alert',{attrs:{"type":"info","outlined":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-3",attrs:{"color":"accent"}},[_vm._v("mdi-information-outline")])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t("plan.customPlanActivated", { transactions: _vm.loggedUser.pricingPlan.transactionLimit, taxYear: new Date().getFullYear() - 1, })))])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-alert',{attrs:{"color":"#635BFF","outlined":"","dense":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-img',{staticClass:"mr-3",attrs:{"src":require("../../assets/plan/STRIPE.svg"),"max-width":"75"}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.$t("plan.stripeDescription")))])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }