var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tab-item',{staticClass:"pa-3",attrs:{"value":"tab-coin"}},[_c('paged-data-table',{attrs:{"namespace":_vm.getNamespace,"itemEndpoint":_vm.getTableEndpoint,"itemsHeaders":_vm.getHeaders,"itemKey":"id","sortBy":"value","sortDesc":true,"query":_vm.getSearchQuery,"showExpand":true,"isCard":false},scopedSlots:_vm._u([{key:"item.coin",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-2"},[_c('v-avatar',{attrs:{"size":"25","color":_vm.getCoinAvatarColor(item)}},[(_vm.getCoinImage(item))?[_c('v-img',{attrs:{"src":_vm.getCoinImage(item)}})]:[_c('span',{staticClass:"white--text fit-font"},[_vm._v(_vm._s(item.coin.symbol ? item.coin.symbol[0] : ""))])]],2)],1),_c('span',[_vm._v(_vm._s(item.coin.name))])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("CurrencyFormatter")(item.totalBalance,item.coin ? item.coin.symbol : null))+" ")]}},{key:"item.unitPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("RoundPriceFormatter")(_vm.getCoinPrice(item),_vm.loggedUser.settings.currency))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("CurrencyFormatter")(item.totalValue,_vm.loggedUser.settings.currency))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.coin.coingeckoId)?_c('external-link-button',{attrs:{"redirect":("https://www.coingecko.com/coins/" + (item.coin.coingeckoId))}}):_vm._e(),(!item.coin.coingeckoId)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v("mdi-alert-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("misc.coinWarning")))])])],1):_vm._e()]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [(!_vm.$route.params.address)?_c('td',{staticClass:"text-start"},[_c('v-btn',{staticClass:"v-data-table__expand-icon",class:{ 'v-data-table__expand-icon--active': isExpanded },attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var balanceItem = ref.item;
return [(!_vm.$route.params.address)?_c('td',{attrs:{"colspan":_vm.getHeaders.length}},[_c('v-data-table',{staticClass:"expanded-datatable",attrs:{"disable-pagination":true,"headers":_vm.getExpandedHeaders,"items":balanceItem.balances,"hide-default-footer":true,"sort-by":"balance","sort-desc":true},scopedSlots:_vm._u([{key:"item.address",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("PersonalAddressFormatter")(item.address))+" ")]}},{key:"item.blockchainType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mr-2"},[_c('v-avatar',{attrs:{"size":"25"}},[_c('v-img',{attrs:{"src":require(("../../assets/blockchain/" + (item.blockchainType) + ".svg")),"alt":"","width":"25","aspect-ratio":"1","contain":""}})],1)],1),_c('span',[_vm._v(_vm._s(_vm._f("BlockchainTypeFormatter")(item.blockchainType)))])]}},{key:"item.coin",fn:function(ref){
var balanceItem = ref.balanceItem;
return [_c('span',{staticClass:"mx-1"},[_c('v-avatar',{attrs:{"size":"30","color":_vm.getCoinAvatarColor(balanceItem)}},[(_vm.getCoinImage(balanceItem))?[_c('v-img',{attrs:{"src":_vm.getCoinImage(balanceItem)}})]:[_c('span',{staticClass:"white--text fit-font"},[_vm._v(_vm._s(balanceItem.coin.symbol ? balanceItem.coin.symbol[0] : ""))])]],2)],1),_c('span',[_vm._v(_vm._s(balanceItem.coin.name))])]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("CurrencyFormatter")(item.balance, balanceItem.coin ? balanceItem.coin.symbol : null ))+" ")]}},{key:"item.unitPrice",fn:function(ref){
var balanceItem = ref.balanceItem;
return [_vm._v(" "+_vm._s(_vm._f("RoundPriceFormatter")(_vm.getCoinPrice(balanceItem),_vm.loggedUser.settings.currency))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("CurrencyFormatter")((item.balance * _vm.getCoinPrice(balanceItem)),_vm.loggedUser.settings.currency))+" ")]}}],null,true)})],1):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }