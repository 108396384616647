<template>
        <ul class="wrapper">
            <li class="icon website">
                <v-btn class="social-button" icon :href="website" target="_blank">
                    <v-icon>
                     mdi-web
                    </v-icon>
                </v-btn>
            </li>
            <!--
            <li class="icon discord">
                <v-btn class="social-button" icon :href="discord" target="_blank">
                    <v-icon class="fa-brands fa-discord" size="18px"></v-icon>
                </v-btn>
            </li>
            -->
            <li class="icon instagram">
                <v-btn class="social-button" icon :href="instagram" target="_blank">
                    <v-icon>
                     mdi-instagram
                    </v-icon>
                </v-btn>
            </li>
            <li class="icon twitter">
                <v-btn class="social-button" icon :href="twitter" target="_blank">
                    <v-icon>
                     mdi-twitter
                    </v-icon>
                </v-btn>
            </li>
            <li class="icon linkedin">
                <v-btn class="social-button" icon :href="linkedin" target="_blank">
                    <v-icon>
                     mdi-linkedin
                    </v-icon>
                </v-btn>
            </li>
        </ul>
</template>

<script>
export default {
    name: 'social-component',
    data() {
        return {
            website: "https://okipo.io/",
            linkedin: "https://www.linkedin.com/company/okipo-io/",
            instagram: "https://www.instagram.com/okipo_io/",
            twitter: "https://twitter.com/okipo_io",
            facebook: "https://www.facebook.com/Okipo-110489108319408",
            discord: "https://discord.gg/W4p4YUDWfV"
        }
    },
    computed: {
    }

}
</script>

<style lang="scss" scoped >

.wrapper {
    display: inline-flex;
    list-style: none;
    padding: 0px;
}

.wrapper .icon {
    position: relative;
    border-radius: 50%;
    padding: 10px;
    margin: 5px;
    width: 35px;
    height: 35px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.social-button::before {
    display: none;
}
.wrapper .icon .v-icon {
    color: #757575
}

.wrapper .website:hover {
    background: var(--v-accent-base);
}
.wrapper .facebook:hover {
    background: #1877F2;
}
.wrapper .discord:hover {
    background: #7289DA;
}
.wrapper .linkedin:hover {
    background: #0072b1;
}

.wrapper .twitter:hover {
    background: #1DA1F2;
}

.wrapper .instagram:hover {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);

}

.wrapper .website:hover .v-icon,
.wrapper .facebook:hover .v-icon,
.wrapper .discord:hover .v-icon,
.wrapper .linkedin:hover .v-icon,
.wrapper .twitter:hover .v-icon,
.wrapper .instagram:hover .v-icon {
    color: #ffffff;
}
</style>