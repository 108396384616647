<template>
  <v-container fluid>
    <v-row class="my-10">
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
      <v-col>
        <payment-successful-card></payment-successful-card>
      </v-col>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
    </v-row>
    <v-row :align="'center'" :justify="'center'" no-gutters>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <router-button
          :text="$t('connection.backHome')"
          redirect="/overview"
        ></router-button>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import PaymentSuccessfulCard from "../../components/card/PaymentSuccessfulCard.vue";
import RouterButton from "../../components/button/RouterButton.vue";
export default {
  name: "payment-success-page",
  components: {
    PaymentSuccessfulCard,
    RouterButton,
  },
};
</script>

<style>
</style>