<template>
  <v-card v-if="isCard">
    <v-data-table :headers="itemsHeaders" :items="getItems" :server-items-length="getItemsNumber" :loading="loading"
      :options.sync="options" :item-key="itemKey" :sort-by="sortBy" :sort-desc="sortDesc" :multi-sort="false"
      :items-per-page="itemsPerPage"
      :footer-props="{ 'items-per-page-options': itemsPerPageOptions, 'showFirstLastPage': true }" @click:row="clickOnRow"
      :single-expand="true" :expanded="expanded"
      :show-expand="showExpand" class="main-table pa-3">
      <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData"></slot>
      </template>
    </v-data-table>
  </v-card>
  <v-data-table v-else :headers="itemsHeaders" :items="getItems" :server-items-length="getItemsNumber" :loading="loading"
    :options.sync="options" :item-key="itemKey" :sort-by="sortBy" :sort-desc="sortDesc" :multi-sort="false"
    :items-per-page="itemsPerPage"
    :footer-props="{ 'items-per-page-options': itemsPerPageOptions, 'showFirstLastPage': true }" @click:row="clickOnRow"
    :single-expand="true" :expanded="expanded"
    :show-expand="showExpand" class="main-table pa-3">
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData"></slot>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { pagedTable } from "@/store/modules/pagedTable";
import store from "@/store/index";

export default {
  props: {
    isCard: {
      type: Boolean,
      required: false,
      default: true
    },
    itemsHeaders: {
      type: Array,
      required: true,
    },
    itemEndpoint: {
      type: String,
      required: true,
    },
    // Must be unique to avoid namespace collisions
    namespace: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      required: false,
      default: "id",
    },
    sortBy: {
      type: String,
      required: false,
      default: null,
    },
    sortDesc: {
      type: Boolean,
      required: false,
      default: true
    },
    query: {
      type: String,
      required: false,
    },
    refresh: {
      type: Boolean,
      required: false,
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10,
    },
    itemsPerPageOptions: {
      type: Array,
      required: false,
      default: () => [5, 10, 20, 50],
    },
    showExpand: {
      type: Boolean,
      required: false,
      default: false
    },
    expanded: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      options: {},
      loading: true,
    };
  },
  methods: {
    ...mapActions({
      fetchItems(dispatch, payload) {
        return dispatch(`${this.namespace}/fetchItems`, payload);
      },

    }),

    clickOnRow(row) {
      this.$emit("clickOnRow", row)
    },
    retrieveItems(opt) {
      this.loading = true;
      let sortKey = opt.sortBy[0];

      this.fetchItems({
        page: opt.page - 1,
        pageSize: opt.itemsPerPage,
        sortBy: sortKey || null,
        sortDesc: opt.sortDesc[0] || null,
        search: this.query
      }).then(() => {
        this.$emit("dataFetched", true)
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapState({
      state(state) {
        return state[this.namespace];
      },
      getItems(state, getters) {
        return getters[this.namespace + "/getItems"];
      },
      getItemsNumber(state, getters) {
        return getters[this.namespace + "/getItemsNumber"];
      },
    }),
    params() {
      return {
        options: this.options,
      };
    },
  },
  watch: {
    params: {
      handler(params) {
        this.retrieveItems(params.options);
      },
      deep: true,
    },
    query: function (val) {
      val == "" ? null : this.retrieveItems(this.options);
    },
    refresh: function (val) {
      if (val) {
        this.retrieveItems(this.options)
      }
    }
  },

  created() {
    // Register the new module dynamically
    if (!this.$store.state[this.namespace]) {
      store.registerModule(this.namespace, pagedTable);
    }

    // Set endpoint for API
    this.$store.commit(`${this.namespace}/setItemEndpoint`, this.itemEndpoint);
  },
  // destroyed() {
  //   if (!module.hot) {
  //     // Prevent webpack HRM to destroy the store
  //     store.unregisterModule(this.namespace);
  //   }
  // },
};
</script>

<style scoped>
</style>