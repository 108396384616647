<template>
  <div>
    {{ getUpdated }}
    <sync-addresses-button :addresses="getAddressesToSync" :onlyIcon="true" @successfulSync="successfulSync" v-if="!address.updating && !(address.addressType == 'EXCHANGE')">
    </sync-addresses-button>
  </div>
</template>

<script>
import SyncAddressesButton from '../button/SyncAddressesButton.vue';
export default {
  components: { SyncAddressesButton },
  name: 'address-status-component',
  props: {
    address: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    getAddressesToSync() {
      if (this.address && this.address.address) {
        return [this.address.address]
      }
      return []
    },
    getUpdated(){
      if (this.address.updating || this.address.updated == null){
        return this.$t('address.updating')
      } else {
        return this.$t('address.lastSync') + " " + this.$options.filters.LastDateFormatter(this.address.updated); 
      }
    }
  },
  methods: {
    successfulSync(successfulSync) {
      this.$emit("successfulSync", successfulSync); // Transfer emit to parent component
    }
  }

}
</script>

<style>
</style>