<template>
  <v-tab-item value="tab-history" class="pa-3">
    <!--
    <v-row class="my-1">
      <v-col xl="4" lg="4" md="4" sm="6" xs="12">
        <transaction-action-type-filter @filterTransactionType="applyTransactionTypeFilter">
        </transaction-action-type-filter>
      </v-col>
      <v-col xl="4" lg="4" md="4" sm="6" xs="12">
        <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="dates"
        persistent
        width="600px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dates"
            :label="$t('label.dateRange')"
            prepend-inner-icon="mdi-calendar"
            :hint="$t('label.dateRange')"
            persistent-hint
            readonly
            v-bind="attrs"
            v-on="on"
            solo
          >
        </v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          range
          width="600px"
          clearable
          color="accent"
        >
          <v-btn 
            text color="error" @click="$refs.dialog.save([])">{{$t('button.clear')}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            {{$t('button.cancel')}}
          </v-btn>
          <v-btn
            color="accent"
            @click="$refs.dialog.save(dates)"
          >
            {{$t('button.confirm')}}
          </v-btn>
        </v-date-picker>
      </v-dialog>
      </v-col>
    </v-row>
    -->
    <paged-data-table
      :namespace="getNamespace"
      :itemEndpoint="getTableEndpoint"
      :itemsHeaders="getHeaders"
      itemKey="id"
      sortBy="created"
      :sortDesc="true"
      :query="getSearchQuery"
      @clickOnRow="handleClickOnRow"
      :isCard="false"
    >
      <template v-slot:[`item.blockchainType`]="{ item }">
        <v-img
          :src="require(`../../assets/blockchain/${item.blockchainType}.svg`)"
          alt=""
          width="25"
          aspect-ratio="1"
          contain
        ></v-img>
      </template>
      <template v-slot:[`item.address`]="{ item }">
        {{ item.address | PersonalAddressFormatter }}
      </template>
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | DateFormatter }}
      </template>
      <template v-slot:[`item.transactionActionType`]="{ item }">
        <template v-if="item.transactionActionType != 'UNIDENTIFIED'">
          <v-icon>{{
            item.transactionActionType | TransactionActionTypeIconFormatter
          }}</v-icon>
          <span>{{
            item.transactionActionType | TransactionActionTypeStringFormatter
          }}</span>
        </template>
      </template>
      <template v-slot:[`item.received`]="{ item }">
        <received-sent-component
          :movements="item.received"
          class="my-1"
        ></received-sent-component>
      </template>
      <template v-slot:[`item.sent`]="{ item }">
        <received-sent-component
          :movements="item.sent"
          class="my-1"
        ></received-sent-component>
      </template>
      <template v-slot:[`item.fee`]="{ item }">
        <gas-value-component
          v-if="item.fee > 0"
          :blockchainType="item.feeMovement.coin.symbol"
          :quantity="item.fee"
          :value="getFeeCurrencyValue(item)"
        ></gas-value-component>
      </template>
    </paged-data-table>
    <transaction-detail-dialog
      :dialog.sync="dialog"
      :transaction="selectedTransaction"
      :address="selectedAddress"
    ></transaction-detail-dialog>
  </v-tab-item>
</template>

<script>
import PagedDataTable from "../table/PagedDataTable.vue";
import TransactionDetailDialog from "../dialog/TransactionDetailDialog.vue";
import ReceivedSentComponent from "../misc/ReceivedSentComponent.vue";
import GasValueComponent from "../misc/GasValueComponent.vue";
import { mapFields } from "vuex-map-fields";
import { mapState } from "vuex";
//import TransactionActionTypeFilter from "../filter/TransactionActionTypeFilter.vue";
export default {
  name: "history-tab",
  components: {
    PagedDataTable,
    TransactionDetailDialog,
    ReceivedSentComponent,
    GasValueComponent,
    //TransactionActionTypeFilter,
  },
  props: {
    addresses: {
      type: Array,
      required: true,
    },
    blockchainTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      modal: false,
      dates: [],
      selectedAddress: "",
      selectedTransaction: {},
      transactionTypeFilter: "",
      tableNamespace: "history",
    };
  },
  computed: {
    ...mapFields("loggedUser", ["loggedUser"]),
    ...mapState({
      state(state) {
        return state[this.getNamespace];
      },
    }),
    getHeaders() {
      let headers = [];
      if (!this.$route.params.address) {
        headers.push({ text: this.$t("label.address"), value: "address" });
      }
      headers.push(
        ...[
          { text: this.$t("label.date"), value: "created" },
          { text: this.$t("label.blockchain"), value: "blockchainType" },
          {
            text: this.$t("label.transactionActionType"),
            value: "transactionActionType",
          },
          { text: this.$t("label.sent"), value: "sent", sortable: false },
          {
            text: this.$t("label.received"),
            value: "received",
            sortable: false,
          },
          { text: this.$t("label.gasFee"), value: "fee", sortable: false },
        ]
      );
      return headers;
    },
    getTableEndpoint() {
      if (!this.$route.params.address) {
        return `/addressTransaction/search`;
      } else {
        return `/addressTransaction/search/${this.$route.params.address}`;
      }
    },
    getNamespace() {
      if (this.$route.params.address) {
        return this.tableNamespace + "-" + this.$route.params.address;
      } else {
        return this.tableNamespace;
      }
    },
    getSearchQuery() {
      let queries = [];
      if (this.addresses.length > 0) {
        let addressQuery = "address=in=(" + this.addresses.join(",") + ")";
        queries.push(addressQuery);
      } else {
        queries.push("address=isnull=true");
      }
      if (this.blockchainTypes.length > 0) {
        let blockchainQuery =
          "blockchainType=in=(" + this.blockchainTypes.join(",") + ")";
        queries.push(blockchainQuery);
      }
      if (this.transactionTypeFilter != "") {
        let transactionTypeQuery =
          "transactionActionType=in=(" + this.transactionTypeFilter + ")";
        queries.push(transactionTypeQuery);
      }
      if (this.dates.length == 2) {
        let suffix = "T00:00:00+00:00";
        let firstDate = this.dates[0];
        let secondDate = this.dates[1];
        if (firstDate > secondDate) {
          let temp = firstDate;
          firstDate = secondDate;
          secondDate = temp;
        }
        let dateQuery =
          "created=ge=" +
          firstDate +
          suffix +
          ";created=le=" +
          secondDate +
          suffix;
        queries.push(dateQuery);
      }
      let query = queries.join(" and ");
      // query = query + " and platformContract.platformType==ONEINCH" // To test specific contracts
      return query;
    },
  },
  methods: {
    getFeeCurrencyValue(item) {
      let feeValue = 0;
      if (
        item.feePrice &&
        item.feePrice.prices &&
        item.feePrice.prices[this.loggedUser.settings.currency]
      ) {
        feeValue = item.feePrice.prices[this.loggedUser.settings.currency];
      }
      return item.fee * feeValue;
    },
    handleClickOnRow(row) {
      this.dialog = true;
      this.selectedAddress = row.address;
      this.selectedTransaction = row;
    },
    applyTransactionTypeFilter(items) {
      this.transactionTypeFilter = items.join(",");
    },
  },
};
</script>